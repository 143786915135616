import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isBefore } from 'date-fns';
import * as Yup from 'yup';
// form
import { useForm, useFieldArray } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Grid,
  Box,
  Chip,
  Stack,
  Paper,
  Collapse,
  Typography,
  IconButton,
} from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
// components
import { FormProvider, RHFSelect, RHFTextField, RHFSwitch } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';
import { DialogAnimate } from '../../../../components/animate';
import { SkeletonSchedule } from '../../../../components/skeleton';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
import useLocales from '../../../../hooks/useLocales';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import {
  createEditSingleSchedule,
  getSingleSchedule,
  setSingleScheduleDefaults,
  deleteSingleSchedule,
} from '../VenuesSlice';
import {
  CornerCutShapeStyle
} from '../styles';
import { useParams } from 'react-router-dom';
import VenueScheduleForm from "./VenueScheduleForm";
import {formatTimeTo12Hour} from "../../../../utils/formatTime";
const TIMING_SELECTION_INIT_NEW = { "is_open_0": true, "start_time_0": "09:00", "end_time_0": "18:00", "weekday_0": "Monday", "day_0": "MON", "is_open_1": true, "start_time_1": "09:00", "end_time_1": "18:00", "weekday_1": "Tuesday", "day_1": "TUE", "is_open_2": true, "start_time_2": "09:00", "end_time_2": "18:00", "weekday_2": "Wednesday", "day_2": "WED", "is_open_3": true, "start_time_3": "09:00", "end_time_3": "18:00", "weekday_3": "Thursday", "day_3": "THU", "is_open_4": true, "start_time_4": "09:00", "end_time_4": "18:00", "weekday_4": "Friday", "day_4": "FRI", "is_open_5": true, "start_time_5": "09:00", "end_time_5": "18:00", "weekday_5": "Saturday", "day_5": "SAT", "is_open_6": true, "start_time_6": "09:00", "end_time_6": "18:00", "weekday_6": "Sunday", "day_6": "SUN" };




export default function BranchSchedules() {
  const isDesktop = useResponsive('up', 'lg');
  const dispatch = useDispatch();
  const { Translate } = useLocales();
  const { org_id = '', venue_id = '' } = useParams();
  const { facilities, schedules } = useSelector((state) => state.venueOnboardEdit.branch);
  const { venueId, isBranchUpdated } = useSelector((state) => state.venueOnboardEdit);

  const [showNewForm, setShowNewForm] = useState(false);
  const [formData, setFormData] = useState(null);

  const handleDeleteSchedule = (ID) => {

    dispatch(deleteSingleSchedule(venueId, ID, org_id));
  }

  const handleCancel = () => {
    setShowNewForm(false);
  };

  const triggerNewSchedule = (value) => {
    console.log('triggerNewSchedule>>>>');
    dispatch(setSingleScheduleDefaults());
    setFormData(null);
    setShowNewForm(value);
  };

  const handleEditSchedule = (ID) => {
    if (schedules?.length){
      let defaultSchedule = schedules?.find((item)=>item.id == ID)
       if (defaultSchedule){
         const scheduleTimings = defaultSchedule && defaultSchedule.line_items?.reduce((previousValue, currentValue, currentIndex) => {
           const item = {};
           item[`is_open_${currentIndex}`] = currentValue.status_enum === 'OPEN';
           item[`day_${currentIndex}`] = currentValue.day_enum;
           item[`start_time_${currentIndex}`] = currentValue.start_time?.slice(0, 5) || '09:00';
           item[`end_time_${currentIndex}`] = currentValue.end_time?.slice(0, 5) || '18:00';
           return {
             ...previousValue,
             ...item,
           };
         }, {}) || Object.assign(TIMING_SELECTION_INIT_NEW, {});
         const scheduleDefault = {
           "name": defaultSchedule?.name || '',
           "description": defaultSchedule?.description || '',
           "is_default": defaultSchedule?.is_default || false,
           "line_items": scheduleTimings,
           "id": defaultSchedule?.id
         }
           setFormData(scheduleDefault)
       }else{
         dispatch(getSingleSchedule(venueId, ID, org_id));
       }
    }else{
      dispatch(getSingleSchedule(venueId, ID, org_id));
    }

    setShowNewForm(true);
  };

  const handleNewSchedule = () => {
    setShowNewForm(false);
  };


  return (
    <Stack spacing={2} sx={{ ...(isDesktop && { width: '80%' }) }}>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" align="left" >
          {/* <Translate i18nKey="venueCreateEdit.facilities" /> */}
          Timing Schedules
        </Typography>
        <Typography variant="subtitle" align="left">
          Create multiple week timing schedules for Venue Opening Hours
        </Typography>
      </Box>
      <Stack sx={{ mb: 5 }}>
        <PackageList
          venueId={venueId}
          isBranchUpdated={isBranchUpdated}
          list={schedules}
          editSchedule={handleEditSchedule}
          deleteSchedule={handleDeleteSchedule}
          cancelUpdate={handleCancel}
          disableDelete={false}
        />
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
          <Button
            size="small"
            startIcon={<Iconify icon={'eva:plus-fill'} />}
            onClick={() => {
              const toggle = !showNewForm;
              triggerNewSchedule(toggle);
            }}
          >
            Add new schedule
          </Button>
        </Stack>
      </Stack>


      <DialogAnimate open={showNewForm} onClose={handleCancel}>
        <DialogContent>
          {showNewForm && (
            <VenueScheduleForm
              key={showNewForm}
              formData={formData}
              isNew
              scheduleId={null}
              callback={handleNewSchedule}
              cancel={handleCancel}
            />
          )}
        </DialogContent>
      </DialogAnimate>
    </Stack>
  );
}

// ----------------------------------------------------------------------

PackageList.propTypes = {
  list: PropTypes.array,
  venueId: PropTypes.string,
  disableDelete: PropTypes.bool,
  isBranchUpdated: PropTypes.bool,
  editSchedule: PropTypes.func,
  deleteSchedule: PropTypes.func,
  cancelUpdate: PropTypes.func,
};

function PackageList({ list, venueId, editSchedule, deleteSchedule, isBranchUpdated, disableDelete, cancelUpdate }) {
  const { Translate } = useLocales();
  const dispatch = useDispatch();
  const { org_id = '', venue_id = '' } = useParams();

  const [editingID, setEditing] = useState(null);
  const [arrayIndex, setArrayIndex] = useState(null);

  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmPopup, setConfirmPopup] = useState(false);


  const handleClose = () => {
    setConfirmPopup(false);
    setArrayIndex(null);
  };

  const handleDelete = (ID) => {
    if(deleteId)  deleteSchedule(deleteId);

     setDeleteId(null)
    handleClose();
  };

  const handleEdit = (ID) => {
    // setEditing(ID);
    editSchedule(ID)
    // dispatch(getSingleSchedule(venueId, ID, org_id));
    // setTimeout(() => editSchedule(ID), 200);

  };

  const handleEditDone = () => {
    // setEditing(null);
    // setArrayIndex(null);
    cancelUpdate()
  };

  useEffect(() => {
    if (!isBranchUpdated) return;
    console.log('resetting Schedule List useState vals...');
    // setEditing(null);
    // setRemove(null);
    // setArrayIndex(null);
    // setConfirmPopup(false);
    // }, [isBranchUpdated, setEditing, setRemove, setArrayIndex, setConfirmPopup]);
  }, [isBranchUpdated,list]);

  return (
      <>
    <Stack sx={(list && list.length && { mb: 2 }) || { mb: 0 }}>
      <Typography variant="subtitle2" sx={{ mb: 3, display: 'block', color: 'text.secondary' }}>
        List of Timing Schedules {isBranchUpdated}
      </Typography>
      {(list.length && (
        <Stack spacing={1.5} sx={{ bgcolor: 'background.neutral', p: 2, borderRadius: 1 }}>
          {list.map((schedule, index) => {
            return (
              <div key={schedule?.id}>
                {/* <div>editingID {editingID}</div>
              <div>schedule.id {schedule.id}</div>
              <div>arrayIndex {arrayIndex}</div>
              <div>deleteID {deleteID}</div> */}
                <PackageItem

                  index={index}
                  details={schedule}
                  setEdit={handleEdit}
                  setDelete={deleteSchedule}
                  setDeleteId={setDeleteId}
                  openConfirmPopup={setConfirmPopup}
                  disableDelete={disableDelete}
                />
              </div>
            );
          })}
        </Stack>
      )) || (
          <Stack spacing={1.5} sx={{ bgcolor: 'background.neutral', p: 2, borderRadius: 1 }}>
            <small sx={{ color: 'text.secondary' }}>
              <i>No schedule added yet! Please find the option below to add.</i>
            </small>
          </Stack>
        )}
    </Stack>
        <DialogAnimate open={showConfirmPopup} onClose={handleClose}>
          <DialogTitle>Delete schedule</DialogTitle>
          <DialogContent>
            <DialogContentText>Are you sure you want to delete this schedule?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="error" onClick={handleDelete} autoFocus>
              Delete
            </Button>
          </DialogActions>
        </DialogAnimate>
        </>
  );
}
// ----------------------------------------------------------------------

PackageItem.propTypes = {
  details: PropTypes.object,
  index: PropTypes.number,
  setEdit: PropTypes.func,
  setDelete: PropTypes.func,
  openConfirmPopup: PropTypes.func,
  disableDelete: PropTypes.bool,
  isDDR: PropTypes.bool,
};

function PackageItem({
    key,
  details,
  index,
  setEdit,
  setDelete,
  openConfirmPopup,
  disableDelete,
  setDeleteId,
  isDDR,
}) {
  const { Translate } = useLocales();
  const theme = useTheme()
  const borderStyle = `solid 1px ${theme.palette.grey[500_12]}`;
  const { currency, price } = details;
  const minCapacity = details.minimum_capacity;

  const handleDelete = () => {
    setDeleteId(details?.id)
    // setIndex(details.index); // TODO: check index or details.index
    openConfirmPopup(true);
  };

  const handleEdit = () => {
    setEdit(details?.id);
  };
  const showCrossedDelete = isDDR && disableDelete;

  return (
    <Paper   key={details?.id} >
      <Stack spacing={2} sx={{ px: 2, py: 1 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" pb={1} borderBottom={borderStyle}>
          <Box display="flex" justifyContent="space-between" flexDirection="column"  >
          <Typography variant="body2" sx={{ mr: 'auto' }}>
            {details.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary', fontStyle: 'italic' }}>
            {details.description}
          </Typography>
          </Box>
          {(details?.id === 0 && (
            <Typography variant="caption" sx={{ color: 'text.error', fontWeight: 700, mr: 2 }}>
              <Translate i18nKey="roomCreateEdit.new" />
            </Typography>
          )) ||
            ''}
          <Stack direction="row">
            <IconButton
              onClick={handleDelete}
              disabled={showCrossedDelete}
              sx={{ '&:hover': { color: (theme) => theme.palette.error.main } }}
            >
              <Iconify icon={showCrossedDelete && 'tabler:trash-off' || 'eva:trash-2-outline'} width={18} height={18} />
            </IconButton>
            <IconButton
              onClick={handleEdit}
              sx={{ '&:hover': { color: (theme) => theme.palette.primary.main } }}
            >
              <Iconify icon={'eva:edit-fill'} width={18} height={18} />
            </IconButton>
          </Stack>
        </Stack>

        <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Grid container spacing={2} className="mb-4 theme-lato text-base lg:text-xl">

          {details?.line_items
              .filter(({ status }) => status === "Open")
              .map(({ id, day, start_time, end_time }, index) => (
                  <Grid item xs={12} md={3} key={id}>
                    <Box display="flex" justifyContent="space-around" pb={2} borderBottom={borderStyle}>
                      <Typography variant="body2" sx={{ color: 'text.secondary', fontWeight: 500 }}>
                        {day}
                      </Typography>
                      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        {formatTimeTo12Hour(start_time)} - {formatTimeTo12Hour(end_time)}
                      </Typography>
                    </Box>
                  </Grid>
              ))}
        </Grid>
        </Stack>
      </Stack>
    </Paper>
  );
}
// ----------------------------------------------------------------------


