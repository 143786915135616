import React, { useState } from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import {LoadingButton} from "@mui/lab";
import useLocales from "../../hooks/useLocales";
import CheckoutButton from "../buttons/CheckoutButton";
import {useSelector} from "../../redux/store";

function CustomLocalModal({
                         handleSubmit,
                         handleClose,
                         title='global.Warning!',
                         text='global.btn-submit',
                         body = null,
                         sub_title = null,
                         btn = true,
                              modalOpen ,
                              modalLoading = false,
                         description='global.WarningContent'}) {
    const { Translate, translate: t } = useLocales();
    return (
        <Modal
            open={modalOpen}
            onClose={handleClose}
            aria-labelledby="danger-modal-title"
            aria-describedby="danger-modal-description"
        >
            <Box sx={modalStyle}>
                <Typography id="danger-modal-title" variant="h6" component="h2">
                    <Translate i18nKey={title} />
                </Typography>
                {sub_title ? <Typography id="danger-modal-description" >
                    <Translate i18nKey={sub_title}/>
                </Typography> :null }
                {description ? <Typography id="danger-modal-description" sx={{mt: 2}}>
                    <Translate i18nKey={description}/>
                </Typography> : null}
                {body}
                {btn ? <Box sx={{mt: 4, display: 'flex', justifyContent: 'flex-end'}}>
                    <Button onClick={handleClose} color="inherit" sx={{mr: 2}}>
                        <Translate i18nKey={"global.cancel"}/>
                    </Button>
                    {/*<Button onClick={handleClose} color="error" variant="contained">*/}
                    {/*    <Translate i18nKey={"global.danger"} />*/}
                    {/*</Button>*/}
                    <CheckoutButton
                        title={text}
                        loading={modalLoading}
                        onClick={() => handleSubmit()}
                    />
                </Box> : null}
            </Box>
        </Modal>
    );
}

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
};

export default CustomLocalModal;