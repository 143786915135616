import PropTypes from 'prop-types';
import {useCallback, useState} from 'react';
import * as Yup from 'yup';
import PhoneInput,{ isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import {Box, Grid, Card, InputAdornment , Stack, Typography, TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress'; // Import loader component

// redux
import {useDispatch, useSelector} from '../../redux/store';
import { uploadProfileImg, setProfile } from './settingsSlice';
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
import useLocales from '../../hooks/useLocales';
import { setOrgDetail } from '../dashboard/organisation/OrganisationSlice';
import useAuth from 'src/hooks/useAuth';
import axios from "../../utils/axios";
import debounce from 'lodash/debounce';
import {URL_ORGANIZATION} from "../../utils/restApiUrls"; // Import lodash debounce
// ----------------------------------------------------------------------
const BgHighlight = styled(Box)(({ theme }) => ({
  padding: 16,
  borderRadius: 10,
  backgroundColor: theme.palette.background.neutral, // '#F8FBFF',
}));
// ----------------------------------------------------------------------

SettingsSubdomain.propTypes = {
  Orginization: PropTypes.object,
};
// ----------------------------------------------------------------------

export default function SettingsSubdomain({ Orginization }) {
  console.log(Orginization, "Orginization");
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { Translate, translate: t } = useLocales();
  const [subdomainStatus, setSubdomainStatus] = useState(null); // `null` | 'success' | 'error'
    const [isVerifying, setIsVerifying] = useState(false); // Track API verification loading
  const { isLoading} = useSelector((state) => state.orginization);

  const UpdateUserSchema = Yup.object().shape({
    sub_domain: Yup.string().required(t('form.error-subdomain')),
  });

  const defaultValues = {
    sub_domain: Orginization?.sub_domain || ''
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const updatedData = (({ sub_domain }) => ({ sub_domain,
        name: Orginization?.name || '',
        primary_contact_email: Orginization?.primary_contact_email || '',
        image: Orginization?.profile_image || '',
        primary_phone_number: Orginization?.primary_phone_number || '' }))(data);
      dispatch(setOrgDetail(updatedData, user?.organisation_user_details?.
        organisation?.id));
    } catch (error) {
      console.error(error);
    }
  };


  const validateSubdomain = useCallback(
      debounce(async (value) => {
        if (value.length < 3) {
          setSubdomainStatus(null);
          clearErrors('sub_domain');
          return;
        }

        try {
            setIsVerifying(true); // Start loader
          const response = await axios.get(URL_ORGANIZATION.organisationsSubdomainVerify.replace(":org_id",Orginization?.id).replace(":subdomain",value)); // Replace with your API endpoint
          if (!response.data.success) {
            setSubdomainStatus('error');
            setError('sub_domain', {
              type: 'manual',
              message: t('form.error-subdomain-invalid'), // Your custom error message
            });
          } else {
            setSubdomainStatus('success');
            clearErrors('sub_domain');
          }
        } catch (error) {
          console.error('Subdomain validation error:', error);

          setSubdomainStatus('error');
          if (error.response) {
            // Extract all error keys and append messages together
            const errorData = error.response.data.error;
            const message = error.response.data.message || 'form.error-general';
            let combinedMessage = '';

            // Iterate through each key in the error data
            for (const key in errorData) {
              if (errorData.hasOwnProperty(key)) {
                const value = errorData[key];

                // Check if the value is an array
                if (Array.isArray(value)) {
                  combinedMessage += `${key}: ${value.join(', ')} `;
                } else {
                  combinedMessage += `${key}: ${value} `;
                }
              }
            }
            setError('sub_domain', {
              type: 'manual',
              message: t( message + ' ' + combinedMessage.trim()), // Fallback API error message
            });
          } else if (error.request) {
            // The request was made but no response was received
            console.error('Error: No response received', error.request);
          } else {
            // Something happened in setting up the request that triggered an Error
            console.error('Error:', error.message);
          }

        } finally {
            setIsVerifying(false); // Stop loader
        }
      }, 500), // Debounce delay in ms
      [setError, clearErrors, t]
  );


  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ px: 3, py: 3.5 }}>
            { /* PROFILE INFO UPDATE FORM */ ''}
            <Typography variant="subtitle2" color="text.secondary" component="div" sx={{ mb: 0 }}>
              <Translate i18nKey='settings.subdomainInfo' />
            </Typography>
            <Stack spacing={3} alignItems="flex-end" sx={{ mt: 3, mb: 4.5, display: 'block' }}>
              <Controller
                  name={'sub_domain'}
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                      <TextField
                          {...field} fullWidth   error={!!error || subdomainStatus === 'error'}
                          helperText={
                            error?.message}
                          color={subdomainStatus}
                          label="Subdomain"
                          id="outlined-start-adornment"
                          sx={{ m: 1 }}
                          InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {isVerifying ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        ".letswork.io"
                                    )}
                                </InputAdornment>
                            ),
                          }}
                          onChange={(e) => {
                            field.onChange(e); // Update field value
                            validateSubdomain(e.target.value); // Trigger debounce
                          }}
                      />
                  )}
              />


              <LoadingButton type="submit" variant="contained" loading={isLoading} disabled={subdomainStatus != "success"}>
                Save Subdomain
              </LoadingButton>

            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
