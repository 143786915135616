import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import {Controller, useForm} from 'react-hook-form';
// @mui
import {Stack, Box, Typography, Card, Autocomplete, TextField} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// config
import { ROLE_BASED_DISPLAY } from '../../../../config';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { updateLetsWorkSettings, updateVenueAdvanceDetails } from '../VenuesSlice';
// components
import { FormProvider, RHFSelect, RHFSwitch0, RHFTextField } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';

import RoleBasedDisplay, { IsRoleBased } from '../../../../guards/RoleBasedDisplay'
// sections
import {
  CornerCutShapeStyle
} from '../styles';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------
// eslint-disable-next-line
const WEB_URL_REGEX = /^(https?:\/\/)([\w-]+(\.[\w-]+)+)([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;
// ----------------------------------------------------------------------

export default function BranchSettings() {
  const dispatch = useDispatch();
  const { Translate, translate: t } = useLocales();
  const { org_id = '', venue_id = '' } = useParams();
  const [isLoading,setIsLoading] = useState(false);

  const { user } = useAuth();
  const { general, letsWorkSettings } = useSelector((state) => state.venueOnboardEdit.branch);
  const { venueId ,coworkInfoOptions} = useSelector((state) => state.venueOnboardEdit);
  const isEditAble = !ROLE_BASED_DISPLAY.BOTH.includes(user?.system_user);

  const ChangePoliciesSchema = Yup.object().shape({
    is_featured: Yup.bool(),
    is_premium: Yup.bool(),
    is_enabled: Yup.bool(),
    is_publish: Yup.bool(),
    credits_required: Yup.number()
        .when('is_cowork', {
          is: true, // If is_cowork is true
          then: (schema) => schema.typeError(t('Please enter a number as credit'))
              .min(0, t('Credit value must be 0 or more')).required(t('Credits are required when coworking is enabled')),
          otherwise: (schema) => schema.transform((value, originalValue) => (originalValue === '' ? 0 : value)) // Convert empty strings to null
              .nullable().notRequired(),
        }),
    venue_commission: Yup.number()
        .when('is_cowork', {
          is: true, // If is_cowork is true
          then: (schema) => schema.typeError(t('Please enter a number as venue commission')).min(0, t('Venue Checkin Commission value must be 0 or more')).required(t('Venue commission is required when coworking is enabled')),
          otherwise: (schema) => schema.transform((value, originalValue) => (originalValue === '' ? 0 : value)) // Convert empty strings to null
              .nullable().notRequired(),
        }),
    venue_booking_commission_percentage: Yup.number().min(0, t('Letswork Booking Commission Percentage must be 0 or more')).required(t('This field is required')),
    contract_link: Yup.string()
        .matches(WEB_URL_REGEX, t('form.error-url'))
        .when('is_publish', {
          is: true, // If is_publish is true
          then: (schema) => schema.required(t('Contract link is required before publishing venue')),
          otherwise: (schema) => schema.notRequired(),
        }),
    status: Yup.string().required(t('This field is required')),
  });

  const letswork_perks = letsWorkSettings.letswork_perks?.length ? letsWorkSettings.letswork_perks.map(item => ({
    id:item.public_id,title:item.title
  })) : []

  const defaultValues = {
    ...letsWorkSettings,
    letswork_perks: letswork_perks,
    is_cowork: general?.is_cowork,
  };

  const methods = useForm({
    resolver: yupResolver(ChangePoliciesSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { isSubmitting },
  } = methods;
  const statusType = watch('status');
  const onSubmit = async (data) => {
    try {
      setIsLoading(true)
      const newSettings = {
        ...data,
        letswork_perks: data.letswork_perks.map(item => item.id),
      };
      dispatch(updateLetsWorkSettings(newSettings, 'letswork_settings', venueId, org_id,setIsLoading));
    } catch (error) {
      console.error(error);
    }
  };


  return (
    <>
      <Box sx={{ pb: 4 }}>
        <Typography variant="h4" align="left" >
          Venue Settings
        </Typography>
      </Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.WORK}>
          <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="start" justifyContent="center" sx={{ my: 3 }}>
            <RHFTextField disabled={isEditAble} type="Number" name="venue_commission" label={`${t('Venue Checkin Commission')}(${general?.currency_code})`} />
            <RHFTextField disabled={isEditAble} type="Number" name="credits_required" label={t('Credits Required')} />
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="start" justifyContent="center" sx={{ my: 3 }}>
            <RHFTextField disabled={isEditAble} type="Number" name="venue_booking_commission_percentage" label={t('Letswork Booking Commission Percentage(%)')} />
            <RHFSelect disabled={isEditAble} name="status" label={t('Status')} sx={{ mb: 2 }} onChange={(e)=>{
              if (e.target.value !== "APPROVED"){
                setValue('is_publish', false);
              }
            }}>
              <option disabled value="" />
              {[{ key: "PENDING", value: "Pending" }, { key: "REJECTED", value: "Reject" }, { key: "APPROVED", value: "Approved" }].map((option, index) => (
                <option key={`status${option.key}`} value={option.key}>
                  {option.value}
                </option>
              ))}
            </RHFSelect>
          </Stack>
          <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="start" justifyContent="center" sx={{ my: 3 }}>
            <RHFTextField disabled={isEditAble} name="contract_link" label={t('Contract Link')} />
          </Stack>
          <Controller
              name="letswork_perks"
              disabled={isEditAble}
              control={control}
              render={({ field }) => (
                  <Autocomplete
                      {...field}
                      multiple
                      onChange={(event, newValue) => {
                        console.log("onchange", newValue, typeof newValue)
                        field.onChange(newValue)
                      }}
                      options={coworkInfoOptions?.letswork_perks?.length ? coworkInfoOptions?.letswork_perks : [] }
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                      autoHighlight
                      getOptionLabel={(option) => option.title}
                      renderOption={(props, { id, title }) => {
                        const { key, ...optionProps } = props;
                        return (
                            <Box
                                key={key}
                                component="li"
                                {...optionProps}
                            >
                              {title}
                            </Box>
                        );
                      }}
                      renderInput={(params) => <TextField label="Letswork Perks" {...params} />}
                  />
              )}
          />
        </RoleBasedDisplay>

        <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.WORK}>
          <Stack spacing={3} direction={{ xs: 'row', md: 'row' }} alignItems="start" sx={{ my: 3 }}>
            <RHFSwitch0 disabled={isEditAble} name="is_featured" labelPlacement="start" label={t("Featured")} />
            <RHFSwitch0 disabled={isEditAble} name="is_premium" labelPlacement="start" label={t("Premium")} />
            {/*<RHFSwitch0 disabled={isEditAble} name="is_enabled" labelPlacement="start" label={t("Enabled")} />*/}
            {statusType === "APPROVED" ? <RHFSwitch0 disabled={isEditAble} name="is_publish" labelPlacement="start" label={t("Publish")}/> : null}
          </Stack>
        </RoleBasedDisplay>
        {!isEditAble &&
          <Stack spacing={3} alignItems="flex-start">
            <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
              <LoadingButton type="submit" size="large"  variant="contained" loading={isLoading}>
                <Translate i18nKey='global.btn-saveChanges' />
              </LoadingButton>
            </CornerCutShapeStyle>
          </Stack>
        }
      </FormProvider>
    </>
  );
}
