import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { useParams, Link as RouterLink } from 'react-router-dom';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Box, Avatar, Grid, Card, Link, CardContent, Typography, Stack,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup } from '@mui/material';
// components
import { FormProvider } from '../../../../components/hook-form';
import Iconify from '../../../../components/Iconify';// components
import { SkeletonProductItem } from '../../../../components/skeleton';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useLocales from '../../../../hooks/useLocales';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { updateVenueAdvanceDetails } from '../VenuesSlice';
// sections
import {
  CornerCutShapeStyle
} from '../styles';
//
import SpaceCard from '../list/VenueCard';
import useAuth from "../../../../hooks/useAuth";
import React from "react";
import SkeletonSpaceCard from "../../../../components/skeleton/SkeletonSpaceCard";

// ----------------------------------------------------------------------

const HiddenCheckbox = styled(Checkbox)(() => ({
  color: 'red',
  display: 'none',
}));

const typeLookUp = {
  "meet": {
    "space_type": "MEETING_ROOM" ,
    "title": "Manage Meeting Room"
  },
  "office": {
    "space_type": "OFFICE",
    "title": "Manage Offices"
  },
  "creative": {
    "space_type": "CREATIVE",
    "title": "Manage Creative Studios"
  }
 }

// ----------------------------------------------------------------------
BranchSpaceListing.propTypes = {
  type: PropTypes.string,
};

export default function BranchSpaceListing({ type}) {
  const dispatch = useDispatch();
  const { Translate } = useLocales();
    const allParams = new URLSearchParams(window.location.search);
  const { org_id='', venue_id = '' } = useParams();
  const { venueId,spaces,isSpaceLoading } = useSelector((state) => state.venueOnboardEdit);

  const count = spaces && (spaces.filter(item => item.space_type === typeLookUp[type].space_type))?.length || 0;
    let linkToNewSpace = `${PATH_DASHBOARD.organisation.root}/${org_id}/venues/${venue_id}/onboard?type=${type}`;

    // Append existing query parameters
    allParams?.forEach((value, key) => {
        if (key !== 'type') { // Avoid overriding the `type` parameter
            if(key === 'tab'){
                linkToNewSpace +=`&${key}=general`
            }else{
                linkToNewSpace += `&${key}=${value}`;
            }

        }
    });

    if(isSpaceLoading){
        return <SkeletonSpaceCard children={ <Typography variant="h4" align="left" >
            {typeLookUp[type].title}
        </Typography>}/>
    }

  return (
    // <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={10}>
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between"  sx={{ mb: 3, width: '100%' }}>
                <Box sx={{pb:4}}>
                  <Typography variant="h4" align="left" >
                    {typeLookUp[type].title}
                  </Typography>
                  <Typography variant="subtitle" align="left">
                    { `${spaces && count || 0} Room(s)` || ''}
                  </Typography>
                </Box>
            </Stack>
            {(spaces && !count || !spaces) && (
              <Typography variant="subtitle" align="left">
                Start adding your first room!
              </Typography>
            )}
          
            <Box
              sx={{
                display: 'grid',
                gap: 3,
                gridTemplateColumns: {
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(2, 1fr)',
                  md: 'repeat(3, 1fr)',
                  lg: 'repeat(4, 1fr)',
                },
              }}
            >
              { spaces.filter(item => (item?.space_type === typeLookUp[type]?.space_type)).map((space, index) =>
                <SpaceCard key={space.id} space={space} />
              )}
            </Box>

            <Box>
              <CornerCutShapeStyle sx={{ position: 'relative'}}>
                <LoadingButton component={RouterLink} to={linkToNewSpace} type="submit" size="large"  variant="contained" startIcon={<Iconify icon={'eva:plus-fill'} />}>
                  Add New Room
                </LoadingButton>
              </CornerCutShapeStyle>
            </Box>

            

              
          </Stack>
        </Grid>
      </Grid>
    // </FormProvider>
  );
}
// ----------------------------------------------------------------------