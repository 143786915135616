import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { URL_SETTINGS } from '../../utils/restApiUrls';
//
import { dispatch } from '../../redux/store';

// ----------------------------------------------------------------------
const PROFILE_FETCH_ERROR = 'settings.error.fetchProfile';
const PROFILE_UPDATE_SUCCESS = 'settings.success.updateProfile';
const PROFILE_UPDATE_ERROR = 'settings.error.updateProfile';
const PROFILE_IMG_UPLOAD_SUCCESS = 'settings.success.uploadImage';
const PROFILE_IMG_UPLOAD_ERROR = 'settings.error.uploadImage';
const PROFILE_PWD_UPDATE_SUCCESS = 'settings.success.updatePassword';
const PROFILE_PWD_UPDATE_ERROR = 'settings.error.updatePassword';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: null,
  alertError: null,
  alertSuccess: null,
  profile: null,
  isProfileUpdated: false,
};

const slice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // START UPDATING
    startUpdating(state) {
      state.isProfileUpdated = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // Display Alert Success
    setAlertSuccess(state, action) {
      const message = action.payload;
      state.alertSuccess = message;
    },

    // Display Alert Error
    setAlertError(state, action) {
      const message = action.payload;
      state.alertError = message;
    },

    // Reset Alert Success
    resetAlertSuccess(state) {
      state.alertSuccess = null;
    },

    // Reset Alert Error
    resetAlertError(state) {
      state.alertError = null;
    },

    // GET BRANCH DETAILS
    getProfileSuccess(state, action) {
      state.isLoading = false;
      const res = action.payload.context ? action.payload.context : action.payload;
      state.profile = res;
    },

    // BRANCH UPDATED - REFETCHed DETAILS
    profileUpdateSuccess(state) {
      state.isProfileUpdated = false;
    },

    // logout
    logoutResetReduxStates(state, action) {
      // This function triggers removal of all persistent storage
      // and reset of redux states from where the root reducer is defined
    }
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { resetAlertSuccess, resetAlertError, logoutResetReduxStates } = slice.actions;

// ----------------------------------------------------------------------

export function refreshProfile() {
  return async () => {
    try {
      const response = await axios.get(URL_SETTINGS.profile);
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getProfileSuccess(getData));
        dispatch(slice.actions.profileUpdateSuccess());
      } else {
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || PROFILE_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(PROFILE_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(PROFILE_FETCH_ERROR));
    }
  };
}

// ----------------------------------------------------------------------

export function getProfile() {
  return async () => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(URL_SETTINGS.profile, {
        params: {  },
      });
      if (response.data.status) {
        const getData = response.data.context;
        dispatch(slice.actions.getProfileSuccess(getData));
      } else {
        dispatch(slice.actions.hasError(PROFILE_FETCH_ERROR));
        dispatch(
          slice.actions.setAlertError(
            response.data.error?.message || response.data.error?.title || PROFILE_FETCH_ERROR
          )
        );
      }
    } catch (error) {
      dispatch(slice.actions.hasError(PROFILE_FETCH_ERROR));
      dispatch(slice.actions.setAlertError(PROFILE_FETCH_ERROR));
    }
  };
}
// ----------------------------------------------------------------------
//              SAVE
// ----------------------------------------------------------------------

export function setProfile(details) {
  return async () => {
    try {
      const response = await axios.post(URL_SETTINGS.setProfile, details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(PROFILE_UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || PROFILE_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(PROFILE_UPDATE_ERROR));
    }
  };
}


// ----------------------------------------------------------------------



export function updateProfile(details,id) {
  return async () => {
    try {
      const response = await axios.put(URL_SETTINGS.updateProfile.replace(":user_id",id), details);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(PROFILE_UPDATE_SUCCESS));
        window.localStorage.setItem('lwt_user', JSON.stringify(response.data.data));
        if (response.data.status) {
          return response;
        }
      } else {
        dispatch(
            slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || PROFILE_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(PROFILE_UPDATE_ERROR));
    }
  };
}

export function updateOrganizationUserProfileImg(data,res) {
  return async () => {
    try {
      const response = await axios.patch(URL_SETTINGS.updateProfileImg, data);
      console.log(response,'updateOrganizationUserProfileImg')
      if (response.data.status) {

        return response.data.data;
      } else {
        dispatch(
            slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || PROFILE_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(PROFILE_UPDATE_ERROR));
    }
  };
}

export function uploadProfileImg(file,org_id) {
  return async () => {
    try {
      const formData = new FormData();
      formData.append('image', file);
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        params: {
          //
        },
      };
      const response = await axios.post(URL_SETTINGS.updateProfileImg.replace(":org_id",org_id), formData, config);
      if (response.data.status) {
        dispatch(slice.actions.startUpdating());
        dispatch(slice.actions.setAlertSuccess(PROFILE_IMG_UPLOAD_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || PROFILE_IMG_UPLOAD_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(PROFILE_IMG_UPLOAD_ERROR));
    }
  };
}
// ----------------------------------------------------------------------

export function updatePassword(details) {
  return async () => {
    try {
      const response = await axios.post(URL_SETTINGS.updatePwd, details);
      if (response.data.status) {
        dispatch(slice.actions.setAlertSuccess(PROFILE_PWD_UPDATE_SUCCESS));
      } else {
        dispatch(
          slice.actions.setAlertError(response.data.error?.message || response.data.error?.title || PROFILE_PWD_UPDATE_ERROR)
        );
      }
    } catch (error) {
      dispatch(slice.actions.setAlertError(PROFILE_PWD_UPDATE_ERROR));
    }
  };
}