import { useEffect, useState } from 'react';
import * as Yup from 'yup';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
// @mui
import { Stack, Box, Typography, Card } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// config
import { ROLE_BASED_DISPLAY } from '../../../../config';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { updateVenueAdvanceDetails } from '../VenuesSlice';
// components
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';

import RoleBasedDisplay, { IsRoleBased } from '../../../../guards/RoleBasedDisplay'
// sections
import {
  CornerCutShapeStyle
} from '../styles';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------
// eslint-disable-next-line
const WEB_URL_REGEX = /^(https?:\/\/)?(www\.)?([\w-]+)\.([a-zA-Z]{2,63})(\.[a-zA-Z]{2,63})?([\/\w-]*)*\/?(\?[^\s#]*)?(#[^\s]*)?$/;

// ----------------------------------------------------------------------

export default function BranchPolicies() {
  const dispatch = useDispatch();
  const { Translate, translate: t } = useLocales();

  const { user } = useAuth();
  const { org_id = '', venue_id = '' } = useParams();
  const { policies, general } = useSelector((state) => state.venueOnboardEdit.branch);
  const { venueId } = useSelector((state) => state.venueOnboardEdit);

  const [ifValidateCoworkVenue, setValidateCoworkVenue] = useState(undefined);
  const [ifValidateLetsmeetVenue, setValidateLetsmeetVenue] = useState(undefined);

  useEffect(() => {
    console.log(general,"general");
    if((ifValidateCoworkVenue !== undefined) && (ifValidateLetsmeetVenue !== undefined)) return;
    if (general.is_cowork) setValidateCoworkVenue(true); else setValidateCoworkVenue(false);
    if (general.is_meeting_room ||  general.is_office_room ||  general.is_creative_studio) setValidateLetsmeetVenue(true); else setValidateLetsmeetVenue(false);
  }, [general.is_cowork, general.is_meeting_room,  general.is_office_room,  general.is_creative_studio]);

  const isRoleBasedCowork = IsRoleBased({ accessibleRoles: ROLE_BASED_DISPLAY.WORK });
  const isRoleBasedLetsmeet = IsRoleBased({ accessibleRoles: ROLE_BASED_DISPLAY.BOOK });

  const PoliciesFields = {};
  PoliciesFields.space_cancellation_policy = ifValidateLetsmeetVenue && isRoleBasedLetsmeet ? Yup.string().required(t('venueCreateEdit.error-cancellationPolicy')):'';
  PoliciesFields.space_checkin_instructions = ifValidateLetsmeetVenue && isRoleBasedLetsmeet ? Yup.string().required(t('venueCreateEdit.error-bookingInstructions')): '';
  PoliciesFields.terms_and_conditions = Yup.string().url().matches(WEB_URL_REGEX, t('form.error-url'));

  const CoworkPoliciesFields = {};
  CoworkPoliciesFields.food_policy = ifValidateCoworkVenue && isRoleBasedCowork ? Yup.string().required(t('venueCreateEdit.error-foodPolicy')):'';
  CoworkPoliciesFields.cowork_checkin_instructions = ifValidateCoworkVenue && isRoleBasedCowork ? Yup.string().required(t('venueCreateEdit.error-checkInInstructions')):'';

  const ChangePoliciesSchema = Yup.object().shape({
    ...PoliciesFields,
    ...(ifValidateCoworkVenue && CoworkPoliciesFields || {})
  });

  const defaultValues = {
    ...policies,
  };

  const methods = useForm({
    resolver: yupResolver(ChangePoliciesSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newPolicies = {
        ...data,
      };
      dispatch(updateVenueAdvanceDetails({policies: newPolicies}, 'policies', venueId, org_id));
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Box sx={{pb:4}}>
        <Typography variant="h4" align="left" >
          Venue Policies
        </Typography>
      </Box>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {
          general.is_cowork && (
            <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.WORK}>
              <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="start" justifyContent="center" sx={{ my: 3 }}>
                <RHFTextField name="food_policy" multiline rows={3} label={t('venueCreateEdit.form-foodPolicy')} />
                <RHFTextField name="cowork_checkin_instructions" multiline rows={3} label={t('venueCreateEdit.form-checkInInstructions')} />
              </Stack>
            </RoleBasedDisplay>
          )||''
        }
        {
          (general.is_meeting_room ||  general.is_office_room ||  general.is_creative_studio) && (
          <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.BOOK}>
            <Stack spacing={3} direction={{ xs: 'column', md: 'row' }} alignItems="start" justifyContent="center" sx={{ my: 3 }}>
              <RHFTextField name="space_cancellation_policy" multiline rows={3} label={t('venueCreateEdit.form-cancellationPolicy')} />
              <RHFTextField name="space_checkin_instructions" multiline rows={3} label={t('venueCreateEdit.form-bookingInstructions')} />
            </Stack>
          </RoleBasedDisplay>
          )||''
        }
        <Stack spacing={3} alignItems="flex-start">
          <RHFTextField name="terms_and_conditions" label={t('venueCreateEdit.form-termsConditions')} type={'url'}/>

          <CornerCutShapeStyle sx={{ mb: 2, position: 'relative'}}>
            <LoadingButton type="submit" size="large"   variant="contained" loading={isSubmitting}>
              <Translate i18nKey='global.btn-saveChanges' />
            </LoadingButton>
          </CornerCutShapeStyle>
        </Stack>
      </FormProvider>
    </>
  );
}
