// @mui
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const CornerCutShapeStyle = styled(Box)(()=>({
  '-webkit-clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 17% 100%, 0% 75%)',
  width: 'fit-content',
}));

export default CornerCutShapeStyle;