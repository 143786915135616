import { useSnackbar } from 'notistack';
import { capitalCase } from 'change-case';
import { useEffect, useState, cloneElement } from 'react';
// @mui
import { Container, Typography, Tab, Box, Tabs } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import {
  resetAlertSuccess,
  resetAlertError
} from '../dashboard/organisation/OrganisationSlice';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import Page from '../../components/Page';
import Iconify from '../../components/Iconify';
import PageError from '../../components/PageError';
import { SkeletonTable } from '../../components/skeleton';
import SettingsGeneral from './SettingsGeneral';
import { getOrgDetail } from '../dashboard/organisation/OrganisationSlice';
import useAuth from 'src/hooks/useAuth';
import SettingsSubdomain from "./SettingsSubdomain";
import {ROLE} from "../../utils/constants";
import {useParams} from "react-router-dom";
// sections

// ----------------------------------------------------------------------
/*

*/


const DEFAULT_TAB = 'general';

// ----------------------------------------------------------------------

export default function MyPlaceBranchCreate() {
  const { enqueueSnackbar } = useSnackbar();
  const { org_id = ''} = useParams();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const currentRole = (user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT)
  const { Translate, translate: t } = useLocales();

  const { Orginization, error, alertSuccess, alertError } = useSelector((state) => state.orginization);

  const SETTINGS_TABS = [ /*
  {
    value: 'branch',
    icon: <Iconify icon={'bxs:building'} width={20} height={20} />,
    component: <SettingsBranch />,
  }, */
    {
      value: 'general',
      icon: <Iconify icon={'gridicons:user'} width={20} height={20} />,
      component: <SettingsGeneral />,
    },
    ...( currentRole ? [{
      value: 'subdomain',
      icon: <Iconify icon={'material-symbols:domain-verification'} width={20} height={20} />,
      component: <SettingsSubdomain />,
    }] : [])
    /*
    /*
    {
      value: 'billing',
      icon: <Iconify icon={'dashicons:awards'} width={20} height={20} />,
      component: <SettingsBilling />,
    },
    {
      value: 'notifications',
      icon: <Iconify icon={'ion:notifications'} width={20} height={20} />,
      component: <SettingsNotifications />,
    }, */
    /* {
      value: 'password',
      icon: <Iconify icon={'jam:key-f'} width={20} height={20} />,
      component: <SettingsChangePassword />,
    }, */
  ];

  const [tabs, setTabs] = useState(0);
  const [currentTab, setCurrentTab] = useState(DEFAULT_TAB);

  useEffect(() => {
    if (tabs) return;
    // set tabs once Orginization details api fetched <<<<
    if(!Orginization) return;
    setTabs(SETTINGS_TABS.slice());
  }, [Orginization, tabs, setTabs]);

  useEffect(() => {
    if(Orginization) return;
    dispatch(getOrgDetail(org_id ? org_id : user?.organisation_user_details?.
      organisation?.id));
  }, [dispatch, Orginization]);


  useEffect(() => {
    if (alertSuccess) {
      enqueueSnackbar(t(alertSuccess), { preventDuplicate: false });
      setTimeout(() => dispatch(resetAlertSuccess()), 50);
    }
    if (alertError) {
      enqueueSnackbar(t(alertError), { variant: 'error', preventDuplicate: false });
      setTimeout(() => dispatch(resetAlertError()), 50);
    }
  }, [alertSuccess, alertError, enqueueSnackbar]);

  return (
    <Page title={t('global.venueSettings')}>
      <Container maxWidth='lg'>
        <Typography variant="h4" component="h1" sx={{ mb: 5}}>
          <Translate i18nKey="settings.title" />
        </Typography>

        {!error && (
          <>
            {tabs ? (
              <>
                <Tabs
                  value={currentTab}
                  scrollButtons="auto"
                  variant="scrollable"
                  allowScrollButtonsMobile
                  onChange={(e, value) => setCurrentTab(value)}
                >
                  {tabs.map((tab) => (
                    <Tab
                      disableRipple
                      key={tab.value}
                      label={t(`settings.${tab.value}`)}
                      icon={tab.icon}
                      value={tab.value}
                    />
                  ))}
                </Tabs>

                <Box sx={{ mb: 5 }} />

                {tabs.map((tab) => {
                  const isMatched = tab.value === currentTab;
                  return isMatched && <Box key={tab.value}>{cloneElement(tab.component, { setTabs, Orginization })}</Box>;
                })}
              </>
            ) : (
              ''
            )}

            {!tabs && (
              <Box sx={{ my: 5, maxWidth: '100%' }}>
                <SkeletonTable />
              </Box>
            )}
          </>
        )}
        {error && (
          <Box>
            <PageError error={error} />
          </Box>
        )}
      </Container>
    </Page>
  );
}
