import * as Yup from 'yup';
import PropTypes from 'prop-types';
// form
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
// @mui
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { Box, Avatar, Grid, Card, Link, CardContent, Typography, Stack,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup } from '@mui/material';
// components
import { FormProvider } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { updateVenueAdvanceDetails } from '../VenuesSlice';
// sections
import {
  CornerCutShapeStyle
} from '../styles';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const HiddenCheckbox = styled(Checkbox)(() => ({
  color: 'red',
  display: 'none',
}));

// ----------------------------------------------------------------------

export default function BranchFacilities() {
  const dispatch = useDispatch();
  const { Translate } = useLocales();
  const { facilities } = useSelector((state) => state.venueOnboardEdit.branch);
  const { user } = useAuth();
  const { facilityOptions, venueId } = useSelector((state) => state.venueOnboardEdit);


  const BranchFacilitiesSchema = Yup.object().shape({
    itemType: Yup.array(),
    fruits: Yup.array(),
  });

  const defaultValues = {
    itemType: facilities && [...facilities] || [],
  };

  const methods = useForm({
    resolver: yupResolver(BranchFacilitiesSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    console.log('onSubmit',data, venueId);
    try {
      const facilities =  data.itemType.map(item=>+item);
      dispatch(updateVenueAdvanceDetails({facilities}, 'facilities', venueId));

    } catch (error) {
      console.error(error);
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={5}>
        <Grid item xs={12} md={10}>
          <Stack spacing={3}>
            {/* <Card sx={{ p: 3 }}> */}
              <Stack direction="row"  justifyContent="space-between"  sx={{ mb: 3, width: '100%' }}>
                <Box sx={{pb:4}}>
                  <Typography variant="h4" align="left" >
                    {/* <Translate i18nKey="venueCreateEdit.facilities" /> */}
                    Facilities at your venue
                  </Typography>
                  <Typography variant="subtitle" align="start">
                    Please select all that apply to your venue
                  </Typography>
                </Box>
                <Box>
                <CornerCutShapeStyle sx={{ position: 'relative'}}>
                  <LoadingButton type="submit" size="large"  variant="contained" loading={isSubmitting}>
                    <Translate i18nKey="global.btn-saveChanges" />
                  </LoadingButton>
                </CornerCutShapeStyle>
                </Box>
              </Stack>

              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <Controller
                    name="itemType"
                    control={control}
                    render={({ field }) => (
                      <Box
                        sx={{
                          display: 'grid',
                          gap: 2,
                          px: 1,
                          gridTemplateColumns: {
                            xs: 'repeat(2, 1fr)',
                            // sm: 'repeat(3, 1fr)',
                            // md: 'repeat(4, 1fr)',
                            // lg: 'repeat(6, 1fr)',
                            sm: 'repeat(3, 1fr)',
                            md: 'repeat(4, 1fr)',
                            lg: 'repeat(5, 1fr)',
                          },
                        }}
                      >
                        {facilityOptions.map((facility) => (
                          <FormControlLabel
                            key={facility.id}
                            label=""
                            control={
                              <>
                              <HiddenCheckbox
                                value={facility.id}
                                checked={field.value.includes(facility.id.toString())}
                                onChange={(event, checked) => {
                                  if (checked) {
                                    field.onChange([
                                      ...field.value,
                                      event.target.value
                                    ]);
                                  } else {
                                    field.onChange(
                                      field.value.filter(
                                        (value) => value !== event.target.value
                                      )
                                    );
                                  }
                                }}
                              />
                              <OptionCard option={facility} selected={field.value.includes(facility.id.toString())} />
                              </>
                            }
                          />
                        ))}
                      </Box>
                    )}
                  />
                </FormGroup>
              </FormControl>
              <Box sx={{ mt: 3 }}>
                <Typography variant="body2" color="text.secondary">
                  <Translate i18nKey="venueCreateEdit.messageUsInfo">
                    Anything missing that you have to offer? Message us at
                    <Link href="mailto:spaces@letswork.io">spaces@letswork.io</Link>.
                  </Translate>
                </Typography>
              </Box>
              <Stack spacing={3} alignItems="flex-start" sx={{ mt: 3 }}>
                <CornerCutShapeStyle sx={{ mb: 2, position: 'relative'}}>
                  <LoadingButton type="submit" size="large"  variant="contained" loading={isSubmitting}>
                    <Translate i18nKey="global.btn-saveChanges" />
                  </LoadingButton>
                </CornerCutShapeStyle>
              </Stack>
            {/* </Card> */}
          </Stack>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
// ----------------------------------------------------------------------

OptionCard.propTypes = {
  option: PropTypes.object,
  selected: PropTypes.bool,
};

function OptionCard({ option, selected }) {
  const { name, icon } = option;
  return (
    <Card
      variant="outlined"
      sx={{
        pt: 3,
        pb: 0,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        boxShadow: 'none',
        ...(( selected && { backgroundColor: 'primary.main' }) || {}),
      }}
    >
      <Avatar alt={name} src={icon} sx={{ width: 30, height: 30, mb: 1 }} />
      <CardContent sx={{ px: 2, py: 0, my: 'auto' }}>
        <Typography variant="body2" sx={{ color: `${selected ? 'white' : 'text.secondary'}`, px: 1, width: '100%', textAlign: 'center' }}>
          {name}
        </Typography>
      </CardContent>
    </Card>
  );
}
