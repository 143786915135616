// config
import { HOST_API,env,HOST_ENV } from '../config';
// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const DOMAIN = 'https://staging.letswork.io'; // 'http://127.0.0.1:8000';
// const DOMAIN2 = "https://staging2.letswork.io";
// const DOMAIN2 = "https://dev-api.letswork.io";
const DOMAIN2 =HOST_API
const V1 = '/v1';
const V2 = '/v2';
const V3 = '/v3';
const ROOT_ONBOARD = `${DOMAIN2}/api${V1}`;
const ROOT_ONBOARD_ORG = `${DOMAIN2}/api${V1}/organisations`;
const ROOTS_LETSMEET = `${DOMAIN}/api${V2}/letsmeet`;
const ROOTS_LETSMEET_V3 = `${DOMAIN}/api${V3}/letsmeet`;
const ROOTS_LETSMEET_AJAX = `${DOMAIN}${V2}/letsmeet/ajax`;
const ROOTS_LM_DASH = `${DOMAIN}/api${V2}/letsmeet/dashboard`;
const ROOTS_BRANCH = `${DOMAIN}/api/branch`;
const ROOTS_BRANCH_V2 = `${DOMAIN}/api${V2}/branch`;
const ROOTS_USER_URL = `${DOMAIN}/api${V2}/users`;
const ROOTS_USER_URL_V1 = `${DOMAIN2}/api${V1}/users`;
const ROOTS_ORG_USER_URL_V1 = `${ROOT_ONBOARD_ORG}/api/users`;
const ROOT_EXPORT = `${HOST_API}${ROOTS_LM_DASH}`;
const ROOTS_MEMBER = `${DOMAIN}/api${V2}/member`;

// ----------------------------------------------------------------------

export const URL_AUTH = {
  login: path(DOMAIN2, '/api/v1/auth/login'),
  refreshToken: path(DOMAIN2, '/api/v1/auth/refresh-token'),
  logout: path(ROOTS_USER_URL, '/logout/'),
  socialLogin: path(DOMAIN2, '/api/v1/auth/social-login/organization'),
  verifyOTP: path(`${DOMAIN}/api/otp`, '/newverify/'),
  verifyOTPLogin: path(`${DOMAIN2}/api/v1/auth`, "/venue/verify-otp"),
  resendOTP: path(`${DOMAIN2}/api/otp`, '/resend/'),
  resetPassword: path(ROOTS_USER_URL, '/reset-password/'),
  setPassword: path(ROOTS_USER_URL, '/set-password/'),
  finishSignUp: path(ROOTS_ORG_USER_URL_V1, '/signup/'),
  signup: path(DOMAIN2, '/api/v1/auth/signup'),
  finishNormalSignUp: path(ROOTS_USER_URL, "/email/signup/complete/"),
  finishVenueSignUp: path(ROOTS_USER_URL, "/email/venuesignup/complete/"),
};

export const URL_CALENDAR = {
  spaces: path(ROOTS_LM_DASH, '/calendar/branch_listing/'), // spaces_listing'),
  create: path(ROOTS_LM_DASH, '/calendar/events/new'),
  delete: path(ROOTS_LM_DASH, '/calendar/events/delete'),
  update: path(ROOTS_LETSMEET, '/space_type/book/'),
  cancel: path(ROOTS_LETSMEET, '/spaces/booking/cancel/'),
  events: path(ROOTS_LETSMEET_AJAX, '/get-calendar-events/'),
  bookings: path(ROOT_ONBOARD, '/orders/bookings/space/:space_id/'),
  createblock: path(ROOT_ONBOARD, '/orders/block-dates/'),
  updateblock: path(ROOT_ONBOARD, '/orders/block-dates/:id/'),
  deleteblock: path(ROOT_ONBOARD, '/orders/block-dates/:id/'),

};

export const URL_BOOKING = {
  details: path(ROOT_ONBOARD, '/orders/bookings/:id/'), // /ID
  updateBooking: path(ROOT_ONBOARD, '/orders/bookings/:id/'), // /ID
  cancelBooking: path(ROOT_ONBOARD, '/orders/bookings/:id/cancel/'),
  initiateOrder: path(ROOT_ONBOARD, "/orders/bookings/venue/initiate-order/"),
  bookingEstimation: path(ROOT_ONBOARD, "/orders/estimation"),
  offlineBooking: path(ROOT_ONBOARD, "/orders/create-offline-booking"),
  completeBooking: path(ROOT_ONBOARD, "/orders/bookings/venue/:id/complete-order/"),
  availableSlots: path(ROOT_ONBOARD, "/orders/available-slots/"),
};

export const URL_SALES = {
  list: path(ROOTS_LM_DASH, '/sales_listing/'),
  chart: path(ROOTS_LM_DASH, '/sales_chart_listing/'),
  export: path(ROOT_EXPORT, '/sales_listing/export/'), // ?duration=day
  details: path(ROOTS_LETSMEET_AJAX, '/get-calendar-event-details/'),
};

export const URL_COWORK_SALES = {
  list: path(ROOTS_LM_DASH, '/checkins_sales_chart_listing/'),
};

export const URL_OVERVIEW = {
  overview: path(ROOTS_LM_DASH, '/home/'),
  checkIns: path(ROOTS_LM_DASH, '/checkins/'),
  checkout: path(ROOTS_MEMBER, '/check_out/'),
};

export const URL_CUSTOMERS = {
  list: path(ROOT_ONBOARD, '/orders/bookings/'),
  export: path(ROOT_EXPORT, '/customers_listing/export/'), // ?duration=day&search_val=A
  checkIns: path(ROOT_ONBOARD, '/venues/checkins/'),
  invalidCheckIns: path(ROOT_ONBOARD, '/venues/checkin/mark-invalid/'),
  reviews: path(ROOT_ONBOARD, '/review/cowork/:id/'),
  userDetails: path(ROOT_ONBOARD, '/customers/info'),
  userCheckIn: path(ROOT_ONBOARD, '/customers/:customer_user_id/check-in'),
  exportAllCheckIns: path(ROOT_ONBOARD, '/venues/checkin/report/'),
  exportAllCheckInsV2: path(ROOT_ONBOARD, '/organisation/:id/checkin-report/'),
  exportAllCheckInsV3: path(ROOT_ONBOARD, '/reports/checkin-report/'),
  exportAllBookingsV2: path(ROOT_ONBOARD, '/organisation/:id/booking-report/'),
  exportAllBookingsV3: path(ROOT_ONBOARD, '/reports/booking-report/'),
};


export const URL_ORGANIZATION = {
  list: path(ROOT_ONBOARD_ORG, '/:org_id/users'),
  add: path(ROOT_ONBOARD_ORG, '/:org_id/users'),
  edit: path(ROOT_ONBOARD_ORG, '/:org_id/users/:user_id'),
  // delete: path(ROOT_ONBOARD, '/customers/delete/:user_id/'),
  delete: path(ROOT_ONBOARD, '/organisations/:org_id/users/:user_id'),
  link: path(ROOT_ONBOARD_ORG, '/:org_id/users/:user_id/venues/link'),
  delink: path(ROOT_ONBOARD_ORG, '/:org_id/users/:user_id/venues/de-link'),
  profile: path(ROOT_ONBOARD_ORG, '/:org_id'),
  organisationsList: path(ROOT_ONBOARD_ORG, '/list/'),
  organisationsSubdomainVerify: path(ROOT_ONBOARD_ORG, '/:org_id/:subdomain/verify'),
};

export const URL_PLACES = {
  branches: path(ROOTS_LM_DASH, '/branch_listing/'),
  spaces: path(ROOTS_LM_DASH, '/space_listing/'),
  cities: path(ROOTS_BRANCH, '/locations/listing/'),
  countries: path(ROOTS_BRANCH, '/countries/listing/'),
  facilityOptions: path(ROOTS_BRANCH, '/facilities/listing/'),
  coworkInfoOptions: path(ROOTS_BRANCH, '/onboarding/information/'),
  supportOptions: path(ROOT_ONBOARD, '/venues/support/'),
  countryOptions: path(ROOT_ONBOARD, '/locations/countries/'),
  cityOptions: path(ROOT_ONBOARD, '/locations/countries/:id/cities/'),
  adminVenues: path(ROOT_ONBOARD, '/venues/'),
  venues: path(ROOT_ONBOARD_ORG, '/:org_id/venues/concise/'),
  conciseVenues: path(ROOT_ONBOARD_ORG, '/:org_id/venue/space/concise'),
  pendingVenues: path(ROOT_ONBOARD_ORG, '/letswork-settings/'),
  createVenue: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/'),
  onboardVenue: path(ROOT_ONBOARD_ORG, '/:org_id/venues/'),
  venueDetails: path(ROOT_ONBOARD_ORG, '/:org_id/venues/comprehensive/:venue_id'), // <id>/
  venueMedia: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/media/upload/'), // ?venue_id=110
  schedules: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/schedules/'), // <schedule id>/  GET POST with and without ID
  letsWorkSettings: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/letswork-settings/'), // <schedule id>/  GET POST with and without ID
  VenueSettings: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/venue-settings/'), // <schedule id>/  GET POST with and without ID
  allSpaces: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/spaces/'),
  space: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/spaces/'), // <space id>/  GET POST with and without ID

  
  branchdetails: path(ROOTS_BRANCH_V2, '/detail/'), // ?branch_id=110 // V1 API for customer portal - V2 API for dashboard
  createBranch: path(ROOTS_LETSMEET, '/branch/'),
  coworkInfo: path(ROOTS_LETSMEET, '/branch/sharedworkspace/'), // ?branch_id=110
  drink: path(ROOTS_LETSMEET, '/branch/recommended_bite/'), // ?branch_id=110
  media: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/spaces/:space_id/media/upload/'), // ?branch_id=110 &space_id=75
  policy: path(ROOTS_LETSMEET, '/branch/policies/'),
  facility: path(ROOTS_LETSMEET, '/branch/facility/'), // for both save
  deleteFacility: path(ROOTS_LETSMEET, '/branch/facility/delete/'), // facility/delete/<branch_id>/<facility_id>/
  notification: path(ROOTS_LETSMEET, '/branch/set-notification-settings/'),
  timings: path(ROOTS_LETSMEET, '/branch/timings/'),
  setupOptions: path(DOMAIN, '/api/letsmeet/setup/listing/'),
  amenityOptions: path(ROOTS_BRANCH, '/amenities/listing/'),
  calendarList: path(ROOT_ONBOARD, '/calendar/:type/oauth/:id/'),
  googleCalendarSync: path(ROOT_ONBOARD, '/calendar/sync/:id/'),
  outlookCalendarSync: path(ROOT_ONBOARD, '/calendar/sync/outlook/:venue_id/:space_id/'),
  linkCalendar: path(ROOTS_LETSMEET, '/add_google_space/'),
  createSpace: path(ROOTS_LETSMEET, '/spaces/add-update/'),
  disableSpace: path(ROOTS_LM_DASH, '/space_disable/'), // <space_id>
  spacedetails: path(ROOTS_LETSMEET, '/spaces/detail/'), // ?branch_id=110&space_id=51&no_currency_change=true
  ddrPackage: path(ROOTS_LETSMEET, '/spaces/ddr-packages/'),
  ddrPackageDelete: path(ROOTS_LETSMEET, '/spaces/ddr-packages/delete/'),
  dateRangeBooking: path(ROOTS_LETSMEET_V3, "/spaces/daterangebooking/listing/"),
  bookRoom: path(ROOTS_LETSMEET_V3, "/spaces/vm/booking/"),
  toggleSpaceActiveInactive: path(ROOT_ONBOARD, "/organisations/:org_id/venues/:venue_id/spaces/:space_id/toggle-active/"),

};

export const URL_SETTINGS = {
  profile: path(ROOTS_USER_URL, '/get-profile/'),
  setProfile: path(ROOTS_USER_URL, '/set-profile/'),
  updateProfile: path(ROOTS_USER_URL_V1, '/:user_id'),
  updateProfileImg: path(ROOT_ONBOARD_ORG, '/:org_id/upload-profile-image'),
  updatePwd: path(ROOTS_USER_URL, '/change-password/'),
  emailLookUp: path(ROOT_ONBOARD, '/team/lookup/'),
  deListTeam: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/team/:team_id/de-list/'),
  whiteListTeam: path(ROOT_ONBOARD_ORG, '/:org_id/venues/:venue_id/team/:team_id/whitelist/'),
};

export const URL_GENERAL = {
  medialUpload: path(ROOT_ONBOARD, '/media/upload-media/'),
};
