import PropTypes from 'prop-types';
import {useCallback, useEffect} from 'react';
import * as Yup from 'yup';
import PhoneInput,{ isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { Box, Grid, Card, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
// redux
import {useDispatch, useSelector} from '../../redux/store';
import { uploadProfileImg } from '../dashboard/organisation/OrganisationSlice';
// components
import { FormProvider, RHFTextField, RHFUploadAvatar } from '../../components/hook-form';
import useLocales from '../../hooks/useLocales';
import {setOrgDetail} from '../dashboard/organisation/OrganisationSlice';
import {resetAlertError, resetAlertSuccess } from './settingsSlice';
import useAuth from 'src/hooks/useAuth';
import PhoneInputWrapper from "../../components/PhoneInputWrapper";
import {enqueueSnackbar} from "notistack";
import {useParams} from "react-router-dom";

// ----------------------------------------------------------------------
const BgHighlight = styled(Box)(({ theme }) => ({
  padding: 16,
  borderRadius: 10,
  backgroundColor: theme.palette.background.neutral, // '#F8FBFF',
}));
// ----------------------------------------------------------------------

SettingsGeneral.propTypes = {
  Orginization: PropTypes.object,
};
// ----------------------------------------------------------------------

export default function SettingsGeneral({}) {
  const dispatch = useDispatch();
  const {user} = useAuth();
    const { org_id = ''} = useParams();
  const {Translate, translate: t} = useLocales();
  const {Orginization,isLoading} = useSelector((state) => state.orginization);
  const UpdateUserSchema = Yup.object().shape({
    name: Yup.string().required(t('form.error-name')),
    primary_contact_email: Yup.string().required(t('form.email')),
    primary_phone_number: Yup.string().test('is-valid-phone', 'Phone number is not valid', value => {
      // Ensure the value is not empty and is a valid phone number
      return value ? isValidPhoneNumber(value) : false;
    }).required(t('form.error-mobile')),
  });

  console.log(Orginization,"Orginization")
  const defaultValues = {
    name: Orginization?.name || '',
    primary_contact_email: Orginization?.primary_contact_email || '',
    image: Orginization?.profile_image_url || '',
    primary_phone_number: Orginization?.primary_phone_number || ''
  };

  const methods = useForm({
    resolver: yupResolver(UpdateUserSchema),
    defaultValues,
  });

  const {
    control,
    handleSubmit,
    formState: {errors, isSubmitting},
  } = methods;

  const onSubmit = async (data) => {
    try {
      const updatedData = (({name, primary_contact_email, primary_phone_number}) => ({
        name,
        primary_contact_email,
        primary_phone_number
      }))(data);
      dispatch(setOrgDetail(updatedData, org_id ? org_id : user?.organisation_user_details?.organisation?.id));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
      (acceptedFiles) => {
        const img = acceptedFiles[0];

        if (img) {
          dispatch(uploadProfileImg(img,org_id ? org_id : user?.organisation_user_details?.organisation?.id))
        }
      },
      [dispatch]
  );

    useEffect(() => {
        if (Orginization) {
            methods.reset({
                name: Orginization.name || '',
                primary_contact_email: Orginization.primary_contact_email || '',
                image: Orginization.profile_image_url || '',
                primary_phone_number: Orginization.primary_phone_number || '',
            });
        }
    }, [Orginization, methods]);

  return (
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card sx={{
              py: 10,
              px: 3,
              textAlign: 'center',
              display: 'flex',
              alignItem: 'center',
              justifyContent: 'center',
              height: '100%'
            }}>
              <RHFUploadAvatar
                  name="image"
                  accept="image/*"
                  maxSize={3145728}
                  onDrop={handleDrop}
                  helperText={
                    <Typography
                        variant="caption"
                        sx={{
                          mt: 2,
                          mx: 'auto',
                          display: 'block',
                          textAlign: 'center',
                          color: 'text.secondary',
                        }}
                    >
                      <Translate i18nKey='settings.imagesAllowed'/>
                    </Typography>
                  }
              />
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card sx={{px: 3, py: 3.5}}>
              { /* PROFILE INFO UPDATE FORM */ ''}
              <Typography variant="subtitle2" color="text.secondary" component="div" sx={{mb: 0}}>
                <Translate i18nKey='settings.orgInfo'/>
              </Typography>
              <Stack spacing={3} alignItems="flex-end" sx={{mt: 3, mb: 4.5, display: 'block'}}>
                <RHFTextField name="name" label={t('global.name')}/>
                <Controller
                    name='primary_phone_number'
                    control={control}
                    render={({field: {onChange, value}, fieldState: {error, isDirty}}) => (
                        <PhoneInputWrapper
                            label='Mobile'
                            className={error ? 'input-error gradientbg' : 'gradientbg'}
                        >
                          <span className={value ? ' mobile-label' : 'mobile-label'}>{t('form.mobile')}</span>
                          <PhoneInput
                              id='primary_phone_number'
                              defaultCountry='AE'
                              value={value}
                              onChange={onChange}
                          />
                          {error && (
                              <Typography variant='caption' color='error.main' sx={{mt: 1, ml: 2}} paragraph>
                                {error.message}
                              </Typography>
                          )}
                        </PhoneInputWrapper>
                    )}
                />
                <RHFTextField name="primary_contact_email" label={t('settings.form-emailAddress')} disabled
                              InputProps={defaultValues.primary_contact_email && {readOnly: true} || {}}/>

                <LoadingButton type="submit" variant="contained" loading={isLoading}>
                  Save Changes
                </LoadingButton>

              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
  );
};
