import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { Card, Box, Grid, Stack, IconButton, Typography,Link } from '@mui/material';
// config
import { ROLE_BASED_DISPLAY } from '../../../../config';
// redux
import {dispatch, useDispatch, useSelector} from '../../../../redux/store';
import { updateVenueAdvanceDetails } from '../VenuesSlice';
// components
import Iconify from '../../../../components/Iconify';
// hooks
import useLocales from '../../../../hooks/useLocales';

import { EmailInput } from '.';

import RoleBasedDisplay from '../../../../guards/RoleBasedDisplay';
import useAuth from 'src/hooks/useAuth';
import { useParams } from 'react-router-dom';
import {setAlertError} from "../../venues/VenuesSlice";

// ----------------------------------------------------------------------

export default function BranchNotifications() {
  const dispatch = useDispatch();
  const { Translate } = useLocales();
  const { org_id = '', venue_id = '' } = useParams();

  const { user } = useAuth();
  const { emails, reportingEmails, general } = useSelector((state) => state.venueOnboardEdit.branch);
  const { venueId } = useSelector((state) => state.venueOnboardEdit);


  const [notifyEmails, setNotifyEmails] = useState(emails);
  const [reportEmails, setReportEmails] = useState(reportingEmails);


  const newOnSubmit = async (data, targetMethod) => {
    console.log('data', data);
    try {
      const newData = { 
        venue_notify_emails: notifyEmails,
        venue_reporting_emails: reportEmails
        // ...(general.is_cowork && {
        //       venue_reporting_emails: reportEmails
        //     } || {})
      }
      if(targetMethod === 'NOTIFY'){
        if (notifyEmails.includes(data.email)) {
          dispatch(setAlertError('Email already exists in notify emails'))
          return; // Stop if the email is already in the notifyEmails list
        }

        const newNotifyEmail = [...notifyEmails, data.email];
        newData.venue_notify_emails = newNotifyEmail;
        setNotifyEmails(newNotifyEmail);
      }
      else if(targetMethod === 'REPORT'){
        if (reportEmails.includes(data.email)) {
          dispatch(setAlertError('Email already exists in report emails'))
          return; // Stop if the email is already in the reportEmails list
        }

        const newReportEmail = [...reportEmails, data.email];
        newData.venue_reporting_emails = newReportEmail;
        setReportEmails(newReportEmail);
      }
      console.log('submit data', JSON.stringify(newData, null, 2));
      dispatch(updateVenueAdvanceDetails({notification:newData},'notifications', venueId, org_id));
      // reset();
    } catch (error) {
      console.error(error);
    }
  }

  const newHandleRemoveEmail = (email, targetMethod) => {
    console.log('handleToggleFacility email', email);
    try {
      const newData = { 
        venue_notify_emails: notifyEmails,
              venue_reporting_emails: reportEmails

      }
      if(targetMethod === 'NOTIFY'){
        const nIndex = notifyEmails.indexOf(email);
        const newNotifyEmail = notifyEmails.slice();
        newNotifyEmail.splice(nIndex, 1);

        newData.venue_notify_emails = newNotifyEmail;
        setNotifyEmails(newNotifyEmail);
      }
      else if(targetMethod === 'REPORT'){
        const rIndex = reportEmails.indexOf(email);
        const newReportEmail = reportEmails.slice();
        newReportEmail.splice(rIndex, 1);

        newData.venue_reporting_emails = newReportEmail;
        setReportEmails(newReportEmail);
      }
      console.log('submit data', JSON.stringify(newData, null, 2));
      dispatch(updateVenueAdvanceDetails({notification:newData},'notifications', venueId, org_id));
    } catch (error) {
      console.error(error);
    }
  };



  return (
    <Grid container spacing={3}>
      <Box sx={{p:3}}>
        <Typography variant="h4" align="left" >
          Reporting Emails
        </Typography>
        <Typography variant="subtitle" align="start">
          Give us a list of emails who would like to receive a monthly generated report
        </Typography>
      </Box>
      <Grid container spacing={2}>

      <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.WORK}>
      {
        general.is_cowork && (
          <Grid item xs={12} md={8} lg={6}>
            <Card sx={{ p: 3, mb: 3 }}>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 0 }}>
                    <Translate i18nKey='venueCreateEdit.coworkEmailTitle' />
                  </Typography>
                </Grid>
                <Grid item xs={12} md={6} lg={8}>
                  <EmailInput key="1" onSubmit={newOnSubmit} targetMethod="REPORT" />
                </Grid>
                {reportingEmails && reportingEmails.length ? (
                  <>
                    <Grid item xs={12} sx={{ mt: 3 }}>
                      <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                        <Translate i18nKey='venueCreateEdit.addedEmails' />
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={7}>
                      <Stack spacing={1}>
                        {reportingEmails.map((emailAddress) => (
                          <EmailCard key={emailAddress} email={emailAddress} onRemove={(data)=>newHandleRemoveEmail(data,'REPORT')} />
                        ))}
                      </Stack>
                    </Grid>
                  </>
                ) : (
                  ''
                )}
              </Grid>
            </Card>
          </Grid>
        ) || ''
      }
      </RoleBasedDisplay>

      <RoleBasedDisplay accessibleRoles={ROLE_BASED_DISPLAY.BOOK}>
        <Grid item xs={12} md={8} lg={6}>
          <Card sx={{ p: 3 }}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="subtitle2" sx={{ color: 'text.secondary', mb: 0 }}>
                    <Translate i18nKey='venueCreateEdit.letsmeetEmailTitle' />
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={8}>
                <EmailInput key="2" onSubmit={newOnSubmit} targetMethod="NOTIFY" />
              </Grid>
              {emails && emails.length ? (
                <>
                  <Grid item xs={12} sx={{ mt: 3 }}>
                    <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                      <Translate i18nKey='venueCreateEdit.addedEmails' />
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6} lg={7}>
                    <Stack spacing={1}>
                      {emails.map((emailAddress) => (
                        <EmailCard key={emailAddress} email={emailAddress} onRemove={(data)=>newHandleRemoveEmail(data,'NOTIFY')} />
                      ))}
                    </Stack>
                  </Grid>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Card>
        </Grid>
      </RoleBasedDisplay>
        </Grid>
    </Grid>
  );
}

function EmailCard({ email, onRemove }) {
  // const { name, role, icon } = friend;
  const [showFull, setShowFull] = useState(false);
  const maxLength = 50;

  const handleToggleShowFull = () => {
    setShowFull((prev) => !prev);
  };

  return (
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Typography
            variant="body2"
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word', maxWidth: '80%' }}
        >
          {showFull ? (
              <>
                {email}{" "}
                <Link
                    component="button"
                    variant="body2"
                    onClick={handleToggleShowFull}
                    sx={{ color: 'primary.main', textDecoration: 'none' }}
                >
                  See Less
                </Link>
              </>
          ) : (
              <>
                {email.slice(0, maxLength)}{email.length > maxLength && '... '}
                {email.length > maxLength && (
                    <Link
                        component="button"
                        variant="body2"
                        onClick={handleToggleShowFull}
                        sx={{ color: 'primary.main', textDecoration: 'none' }}
                    >
                      See More
                    </Link>
                )}
              </>
          )}
        </Typography>
        <IconButton color="error" onClick={() => onRemove(email)}>
          <Iconify icon="eva:trash-2-outline" width={16} height={16} />
        </IconButton>
      </Stack>
  );
}

EmailCard.propTypes = {
  email: PropTypes.string,
  onRemove: PropTypes.func,
};
