// @mui
import {Skeleton, Box, Grid, Stack} from '@mui/material';
import SpaceCard from "../../pages/dashboard/venues/list/VenueCard";
import {SkeletonProductItem} from "./index";
import React from "react";
import {CornerCutShapeStyle} from "../../pages/dashboard/venues/styles";

// ----------------------------------------------------------------------

export default function SkeletonSpaceCard({width ='90%',height=300,children}) {
    return (
        <Grid container spacing={5}>
            <Grid item xs={12} md={10}>
                <Stack spacing={3}>
                    <Stack direction="row" justifyContent="space-between"  sx={{ mb: 3, width: '100%' }}>
                        <Box sx={{pb:4}}>
                            {children}
                            <Skeleton variant="text" height={40} />
                        </Box>
                    </Stack>
                    <Skeleton variant="text" height={40} width={'30%'} />
            <Box
                sx={{
                    display: 'grid',
                    gap: 3,
                    gridTemplateColumns: {
                        xs: 'repeat(1, 1fr)',
                        sm: 'repeat(2, 1fr)',
                        md: 'repeat(3, 1fr)',
                        lg: 'repeat(4, 1fr)',
                    },
                }}
            >
                 <SkeletonProductItem />
                <SkeletonProductItem />
                <SkeletonProductItem />
                <SkeletonProductItem />
            </Box>
                    <CornerCutShapeStyle sx={{ position: 'relative'}}>
            <Skeleton variant="rectangular" width={'171px'} sx={{ height: 41, borderRadius:1, mt:3, display: 'block' }} />
                    </CornerCutShapeStyle>
                </Stack>
            </Grid>
        </Grid>
    );
}
