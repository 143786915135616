import PropTypes from 'prop-types';
import * as Yup from 'yup';
// form
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import { IconButton, Stack } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// components
import Iconify from '../../../../components/Iconify';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';
import CircularProgress from "@mui/material/CircularProgress";
import React from "react";

// ----------------------------------------------------------------------

EmailInput.propTypes = {
  onSubmit: PropTypes.func,
  targetMethod: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  loader: PropTypes.bool,
};

export default function EmailInput({ onSubmit, targetMethod,placeholder='form.placeholder-email',label='venueCreateEdit.form-emailNotifications',loader=false }) {
  const { translate: t } = useLocales();
  const theme = useTheme();

  const UpdateEmailsSchema = Yup.object().shape({
    email: Yup.string().email(t('form.error-validEmail')).required(t('form.error-email')),
  });

  const defaultValues = {
    email: '',
  };

  const methods = useForm({
    resolver: yupResolver(UpdateEmailsSchema),
    defaultValues,
  });

  const { reset, handleSubmit } = methods;

  return (
    <>
    <FormProvider methods={methods} onSubmit={handleSubmit((data)=>{ onSubmit(data,targetMethod); reset(); })}>
      <Stack spacing={1} direction="row" alignItems="center">
        <RHFTextField
          name="email"
          size="small"
          label={t(label)}
          placeholder={t(placeholder)}
        />
        {loader ?
            <CircularProgress size={20}/>:
            <IconButton type="submit">
          <Iconify
              icon={theme.direction === 'ltr' ? 'bi:arrow-right-circle' : 'bi:arrow-left-circle'}
              width={24}
              height={24}
          />
        </IconButton>}
      </Stack>
    </FormProvider>
    </>
  );
}
