import PropTypes from "prop-types";
import useResponsive from "../../../../hooks/useResponsive";
import {useDispatch, useSelector} from "../../../../redux/store";
import {useParams} from "react-router-dom";
import useLocales from "../../../../hooks/useLocales";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {createEditSingleSchedule} from "../VenuesSlice";
import {isBefore} from "date-fns";
import {useEffect} from "react";
import {Button, Stack, Typography} from "@mui/material";
import {SkeletonSchedule} from "../../../../components/skeleton";
import {FormProvider, RHFSelect, RHFSwitch, RHFTextField} from "../../../../components/hook-form";
import {CornerCutShapeStyle} from "../styles";
import {LoadingButton} from "@mui/lab";
const TIMING_SELECTION_INIT = { "is_open_0": true, "start_time_0": "09:00", "end_time_0": "18:00", "weekday_0": "Monday", "day_0": "MON", "is_open_1": true, "start_time_1": "09:00", "end_time_1": "18:00", "weekday_1": "Tuesday", "day_1": "TUE", "is_open_2": true, "start_time_2": "09:00", "end_time_2": "18:00", "weekday_2": "Wednesday", "day_2": "WED", "is_open_3": true, "start_time_3": "09:00", "end_time_3": "18:00", "weekday_3": "Thursday", "day_3": "THU", "is_open_4": false, "start_time_4": "09:00", "end_time_4": "18:00", "weekday_4": "Friday", "day_4": "FRI", "is_open_5": true, "start_time_5": "09:00", "end_time_5": "18:00", "weekday_5": "Saturday", "day_5": "SAT", "is_open_6": true, "start_time_6": "09:00", "end_time_6": "18:00", "weekday_6": "Sunday", "day_6": "SUN" };
const FIELD_REQUIRED = 'roomCreateEdit.error-required';
const FIELD_NUMBER = 'global.error-number';
const HALF_DAY = 'half_day';
const FULL_DAY = 'full_day';
const WEEKDAY_TRANSLATION_KEYS = ['weekday.monday', 'weekday.tuesday', 'weekday.wednesday', 'weekday.thursday', 'weekday.friday', 'weekday.saturday', 'weekday.sunday'];
const TIMING_OPTIONS = [
    '06:00',
    '06:30',
    '07:00',
    '07:30',
    '08:00',
    '08:30',
    '09:00',
    '09:30',
    '10:00',
    '10:30',
    '11:00',
    '11:30',
    '12:00',
    '12:30',
    '13:00',
    '13:30',
    '14:00',
    '14:30',
    '15:00',
    '15:30',
    '16:00',
    '16:30',
    '17:00',
    '17:30',
    '18:00',
    '18:30',
    '19:00',
    '19:30',
    '20:00',
    '20:30',
    '21:00',
    '21:30',
    '22:00',
    '22:30',
    '23:00',
    '23:30',
];
const PREFIX = '2000-01-01T';
const DATE_FORMAT = 'hh:mm aaa';

VenueScheduleForm.propTypes = {
    callback: PropTypes.func,
    cancel: PropTypes.func,
};

export default function VenueScheduleForm({ formData,isNew, scheduleId, callback, cancel }) {
    const isDesktop = useResponsive('up', 'sm');
    const dispatch = useDispatch();
    const { org_id = '', venue_id = '' } = useParams();
    const { Translate, translate: t } = useLocales();
    const { timings } = useSelector((state) => state.venueOnboardEdit.branch);
    const { venueId, schedule, isScheduleFetchLoading, isBranchUpdated } = useSelector((state) => state.venueOnboardEdit);

    console.log(formData?.line_items ,"formData?.line_items ")
    console.log(schedule?.line_items ,"schedule?.line_items ")

    const defaultValues = {
        ...(formData?.line_items || schedule?.line_items || {}),
        ...(formData ? {
            name: formData?.name,
            description: formData?.description,
            is_default: formData?.is_default,
        } : schedule ? {
            name: schedule?.name,
            description: schedule?.description,
            is_default: schedule?.is_default,
        } : {})
    };

    const UpdateBranchSchema = Yup.object().shape({
        name: Yup.string().required(t('form.error-name')),
        description: Yup.string().notRequired(t('form.description')),
        is_default: Yup.bool().required(t('form.error-validMobile')),
        line_items: Yup.array()
    });

    const methods = useForm({
        resolver: yupResolver(UpdateBranchSchema),
        defaultValues,
    });

    const {
        watch,
        getValues,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = methods;

    // const isDateError = isBefore(new Date(values.end), new Date(values.start));

    const onSubmit = async (data) => {
        try {
            const newTimings = [...Array(7)].map((_, index) => {
                const newObj = {
                    status: data[`is_open_${index}`] ? 'OPEN' : 'CLOSE',
                    day: data[`day_${index}`] || TIMING_SELECTION_INIT[`day_${index}`],
                    start_time: data[`start_time_${index}`] || '09:00',
                    end_time: data[`end_time_${index}`] || '18:00'
                }
                return newObj
            });
            const postData = {
                "name": data.name,
                "description": data.description,
                "is_default": data.is_default,
                "line_items": newTimings
            }
            if (formData?.id || schedule?.id) {
                dispatch(createEditSingleSchedule(postData, 'schedules', venueId,formData?.id || schedule?.id, org_id));
            } else {
                dispatch(createEditSingleSchedule(postData, 'schedules', venueId, null, org_id));
            }
        } catch (error) {
            console.error(error);
        }
    };


    const values = watch();

    const isTimeError = (index) =>
        isBefore(
            new Date(`${PREFIX}${values[`end_time_${index}`]}`),
            new Date(`${PREFIX}${values[`start_time_${index}`]}`)
        );
    /*
    useEffect(() => {
      if (scheduleId) {
        // fetch schedule details
        dispatch(getSingleSchedule(venueId, scheduleId));
      } else {
        // set default values for schedule creation
        dispatch(setSingleScheduleDefaults());
      }
    }, []); */

    useEffect(() => {
        if (isScheduleFetchLoading) return;
        // if (schedule && schedule.start_time_0) return;
        if (isBranchUpdated) callback(false);
    }, [isBranchUpdated]);

    // if (!defaultValues || (defaultValues && !defaultValues.start_time_0)) return <Box sx={{ p: 3 }}> Loading.... </Box>;

    return (
        <>

            <Stack spacing={2} sx={{ pr: 3 }}>
                <Typography variant="subtitle1" sx={{ pl: 1, mr: 'auto' }}>
                    Schedule
                </Typography>
                {(!defaultValues || (defaultValues && !defaultValues.start_time_0)) && (
                    <SkeletonSchedule />
                ) || (
                    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                        <Stack spacing={3} alignItems="stretch">
                            <Stack spacing={3} alignItems="stretch" sx={{ pl: 1 }}>
                                <RHFTextField
                                    name={`name`}
                                    label={<Translate i18nKey="Name" />}
                                    size="small"
                                />
                                <RHFTextField
                                    name={`description`}
                                    label={<Translate i18nKey="Description" />}
                                    size="small"
                                />
                            </Stack>
                            {(TIMING_SELECTION_INIT && [...Array(7)] || []).map((timeEntry, index) => (
                                <Stack key={index + 1} direction={isDesktop ? 'row' : 'column'} spacing={2}>
                                    <RHFSwitch
                                        key={index}
                                        name={`is_open_${index}`}
                                        label={t(WEEKDAY_TRANSLATION_KEYS[index])}
                                        sx={{ m: 0, minWidth: '150px' }}
                                    />

                                    {getValues()[`is_open_${index}`] && (
                                        <>
                                            <RHFSelect name={`start_time_${index}`} label={t('global.startTime')} size="small" placeholder="hh:mm p" sx={{ transform: isDesktop && 'scale(0.8)' || '' }}>
                                                <option value="" />
                                                {TIMING_OPTIONS.map((option, index) => (
                                                    <option key={`open${index}`} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </RHFSelect>

                                            <RHFSelect
                                                name={`end_time_${index}`}
                                                label={t('global.endTime')}
                                                size="small"
                                                placeholder="hh:mm p"
                                                error={!!isTimeError(index)}
                                                helperText={isTimeError(index) && t('global.error-endTime')}
                                                sx={{ transform: isDesktop && 'scale(0.8)' || '' }}
                                            >
                                                <option value="" />
                                                {TIMING_OPTIONS.map((option, index) => (
                                                    <option key={`close${index}`} value={option}>
                                                        {option}
                                                    </option>
                                                ))}
                                            </RHFSelect>
                                        </>
                                    )}
                                </Stack>
                            ))}
                            <RHFSwitch
                                name="is_default"
                                label={<Translate i18nKey="Set as default" />}
                            />
                        </Stack>
                        <Stack direction="row" justifyContent="flex-end" spacing={2}>
                            <Button variant="outlined" onClick={cancel}>
                                <Translate i18nKey="global.cancel" />
                            </Button>
                            <CornerCutShapeStyle sx={{ mb: 2, position: 'relative' }}>
                                <LoadingButton type="submit" size="large"  variant="contained" loading={isSubmitting}>
                                    <Translate i18nKey='global.btn-add' />
                                </LoadingButton>
                            </CornerCutShapeStyle>
                        </Stack>
                    </FormProvider>
                )}
            </Stack>

        </>
    );
}