import {useCallback, useState} from 'react';
import { useSnackbar } from 'notistack';
// form
import { useForm } from 'react-hook-form';
// @mui
import { styled } from '@mui/material/styles';
import { Grid, Card, Box, Typography, Stack } from '@mui/material';
// redux
import { useDispatch, useSelector } from '../../../../redux/store';
import { uploadMedia, removeMedia, toggleIsFrontMedia } from '../VenuesSlice';
import { FormProvider, RHFUploadMultiFileIsFront } from '../../../../components/hook-form';
// hooks
import useLocales from '../../../../hooks/useLocales';
import { useParams } from 'react-router-dom';

// ----------------------------------------------------------------------

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));
// ----------------------------------------------------------------------

export default function BranchMediaUpload() {
  const dispatch = useDispatch();
  const { org_id = '', venue_id = '' } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { Translate, translate: t } = useLocales();
  const [progress,setProgress] = useState(0);

  const { images } = useSelector((state) => state.venueOnboardEdit.branch);
  const { venueId,isUploading } = useSelector((state) => state.venueOnboardEdit);

  const key = images?.map((x) => `${x.id}${x.is_front}`).join('-') || 'new-list';

  const defaultValues = {
    // images: images?.slice() || [],
    images,
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = async () => {
    try {
      console.log('on submit');
    } catch (error) {
      console.error(error);
    }
  };

  const handleDrop = useCallback(
    (acceptedFiles) => {
      console.log(acceptedFiles);
      setProgress(0)
      acceptedFiles.map((img) => dispatch(uploadMedia(img, venueId, org_id,(progress) => {
        setProgress(progress);  // Update the progress in the component state
      })));
      // enqueueSnackbar('Update success!'); in loop
    },
    [venueId, dispatch]
  );

  const handleIsFront = (id) => {
    dispatch(toggleIsFrontMedia(id, venueId, org_id));
    // enqueueSnackbar('Update success!');
  };

  const handleRemove = (id) => {
    try {
      if (images.length === 1) {
        enqueueSnackbar(t('global.error-imageRequired'), { variant: 'error' });
        return;
      }
      dispatch(removeMedia(id, venueId, org_id));
    } catch (e) {
      // enqueueSnackbar('Update success!');
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          {/* <Card sx={{ p: 3 }}> */}
            <Stack spacing={3}>
              {images && (
                <div>
                  <Box sx={{pb:4}}>
                    <Typography variant="h4" align="left" >
                      <Translate i18nKey="global.uploadMedia" />
                    </Typography>
                    <Typography variant="subtitle" align="start">
                      Show off your venue by uploading your images below.
                    </Typography>
                  </Box>
                  <RHFUploadMultiFileIsFront
                    key={key}
                    uploaded={images}
                    name="images"
                    showPreview={true}
                    accept="image/*"
                    maxSize={3145728}
                    onDrop={handleDrop}
                    onRemove={handleRemove}
                    progress={progress}
                    isUploading={isUploading}
                    onSetIsFront={handleIsFront}
                  />
                </div>
              )}
            </Stack>
          {/* </Card> */}
        </Grid>
      </Grid>
    </FormProvider>
  );
}
