import PropTypes from 'prop-types';
import {useEffect, useState} from 'react';
import {useParams, Link as RouterLink, useNavigate} from 'react-router-dom';
// @mui
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Divider,
  Button,
  Typography,
  Stack,
  Switch,
  Grid,
  Tooltip
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// redux
import {useDispatch, useSelector} from '../../../../redux/store';
import {
  getGoogleCalendarList,
  disableSpace,
  openCalendarModal,
  setActiveSpaceManageTabs,
  syncGoogleCalendar, syncOutLookCalendar
} from '../VenuesSlice';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// components
import Label from '../../../../components/Label';
import Image from '../../../../components/Image';
import Iconify from '../../../../components/Iconify';
// hooks
import useLocales from '../../../../hooks/useLocales';
// assets
import fallbackSrc from '../../../../assets/placeholder.jpg'
import {CALENDER_TYPE, ROLE, SPACE_TYPE} from "../../../../utils/constants";
import useAuth from "../../../../hooks/useAuth";
import {spaceModeBasedOnUserRole, spaceTabBasedOnUserRole, spaceTabType} from "../../../../utils/helpers";
import {toggleSpaceMode} from "../../spaces/SpacesSlice";
// ----------------------------------------------------------------------

const spaceTypeLabels = {
  offices: 'Private office',
  'meeting-rooms': 'Meeting room',
  creative: 'Creative space',
  work: 'Shared workspace',
};
// ----------------------------------------------------------------------

SpaceCard.propTypes = {
  space: PropTypes.object,
  isLinkLoading: PropTypes.bool,
};

export default function SpaceCard({ space,isLinkLoading }) {
  const dispatch = useDispatch();
  const { Translate, translate: t } = useLocales();
  const { org_id = '', venue_id = '' } = useParams();
  const { user } = useAuth();
  const { id, org_id: orgId, space_id,cowork_id, name, description, cover_photo: cover, is_active, venue_type, space_type: spaceType, currency, price_per_hour: hourlyPrice, price_per_day: dailyPrice } = space;
  const { isLinkModalLoading,calenderLinkType } = useSelector((state) => state.venueOnboardEdit);
  const [authWindow, setAuthWindow] = useState(null);
  // const isUpdateLink = Boolean(space.calendar_id);
  const currentRole = (user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT)
      ? user?.system_user : (user?.organisation_user_details?.role_type ? user?.organisation_user_details?.role_type : user?.system_user);
  const linkTo = !(spaceType === SPACE_TYPE.MEETING_ROOM || spaceType === SPACE_TYPE.OFFICE  || spaceType === SPACE_TYPE.CREATIVE ) ? `${PATH_DASHBOARD.organisation.root}/${orgId ? orgId :org_id }/venues/${id}/edit?mode=${spaceModeBasedOnUserRole(currentRole)}&tab=${spaceTabBasedOnUserRole(currentRole,true)}` : `${PATH_DASHBOARD.organisation.root}/${org_id}/venues/${venue_id}/spaces/${id}/edit?mode=${space ? 'space' :'venue'}&tab=general&type=${spaceTabType(spaceType)}`;
  const linkToVenueCheckIn = `${PATH_DASHBOARD.customers.root}/coworking/${cowork_id}`
  const underVerification = venue_type && !space?.is_publish

  const [switchEnabled, setSwitchEnabled] = useState(is_active);
  const onSubmit = (event) => {
    try {
      setSwitchEnabled(event.target.checked);
      dispatch(toggleSpaceMode(org_id,venue_id,id));
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    const handleAuthMessage = (event) => {
      console.log(event,'event')
      if (event.origin !== window.location.origin) return;
      if (event.data.type === 'AUTH_SUCCESS') {
        if (authWindow) authWindow.close();
        console.log("Authentication successful!");
      }
    };

    window.addEventListener('message', handleAuthMessage);

    return () => {
      window.removeEventListener('message', handleAuthMessage);
    };
  }, [authWindow]);

  const handleOpenModal = async (type) => {
    try {
      const link = await dispatch(getGoogleCalendarList(id, type));

      if (link) {
        window.location.href = link
        // const authWindow = window.location(link);
        // console.log(authWindow,'authWindow')
        // setAuthWindow(authWindow);
      } else {
        console.error("No link provided for calendar authentication.");
      }
    } catch (error) {
      console.error("Failed to open calendar auth modal:", error);
    }
  };


  const handleSpaceSync = async (type)=>{
    if(type === CALENDER_TYPE.GOOGLE){
      dispatch(syncGoogleCalendar(id, type))
    }else if (type === CALENDER_TYPE.OUTLOOK){
      dispatch(syncOutLookCalendar(id,venue_id, type))
    }


  }


  const handleClearCache = () => {
    dispatch(setActiveSpaceManageTabs(0))
  };
  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        {underVerification ? (
          <Label
            variant="filled"
            color="warning"
            sx={{
              top: 16,
              right: 16,
              zIndex: 9,
              fontSize: 8,
              position: 'absolute',
              textTransform: 'uppercase',
            }}
          >
            <Translate i18nKey="global.underVerification" />
          </Label>
        ) : null}
        <Image alt={name} src={cover || fallbackSrc} ratio="4/3"
               onError={(event) => {
          event.target.src = fallbackSrc; // Set the fallback image source
        }} />
      </Box>
      <CardContent sx={{ px: 2, pt: 2, pb: 0 }}>
        <Typography variant="body2" color="text.secondary" noWrap>
          {spaceType?.toUpperCase().replace("_", " ") || venue_type?.toUpperCase().replace("_", " ")}
        </Typography>
        <Typography variant="subtitle1" noWrap gutterBottom>
          {name}
        </Typography>

        <Divider sx={{ width: '100%', mb:2, mt:2 }} />
        {org_id &&
          <Stack direction="row" alignItems="center" sx={{ mt: 2 }} justifyContent="space-between">
            <Stack spacing={0}>
              <Typography variant="caption" fontWeight="500">
                <Translate i18nKey="rooms.hourly" />
              </Typography>
              <Typography variant="body2" fontWeight="500" noWrap>
                {hourlyPrice ? (
                  <>
                    {currency} {hourlyPrice}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Stack>
            <Stack spacing={0}>
              <Typography variant="caption" fontWeight="500">
                <Translate i18nKey="rooms.daily" />
              </Typography>
              <Typography variant="body2" fontWeight="500" noWrap>
                {dailyPrice ? (
                  <>
                    {currency} {dailyPrice}
                  </>
                ) : (
                  '-'
                )}
              </Typography>
            </Stack>
          </Stack>
        }
      </CardContent>
      <CardActions sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        gap: "10px",
        pt: org_id ? 1 : 0,
        pb: '16px'
      }}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'start',
          justifyContent: 'space-between',
          width: "100%"
        }}>
          <ActionItem component={RouterLink} onClick={() => handleClearCache()} to={linkTo} text={t('global.edit')}/>
          <Icons alignself={"right"} space={space} sx={{mr: 'auto'}}/>
          {spaceType ?
              <Switch name="isEnabled" label="" size="small" checked={switchEnabled} onChange={onSubmit}/> : null}
        </Box>

        {!spaceType ? <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'space-between',
          width: "100%",
          marginLeft: '0px !important'
        }}>
          {space?.google_integrated ?
              <GoogleCalendar  status={true} disabled={true} title={'linked'}/> :
              <GoogleCalendar title={'link'} onClick={() => handleOpenModal(CALENDER_TYPE.GOOGLE)}
                              loading={(isLinkLoading && calenderLinkType && calenderLinkType === CALENDER_TYPE.GOOGLE) ? isLinkModalLoading : false}/>}
          {space?.outlook_integrated ?
              <OutlookCalendar  status={true} title={'linked'} disabled={true}/> :
              <OutlookCalendar status={true}  title={'link'} onClick={() => handleOpenModal(CALENDER_TYPE.OUTLOOK)}
                               loading={(isLinkLoading && calenderLinkType && calenderLinkType === CALENDER_TYPE.OUTLOOK) ? isLinkModalLoading : false}/>}
        </Box> : null}

        {spaceType ? <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'space-between',
          width: "100%",
          marginLeft: '0px !important'
        }}>
          {space?.is_google_calendar_integrated ?
              <GoogleCalendar title={'Sync'} onClick={() => handleSpaceSync(CALENDER_TYPE.GOOGLE)}
                              loading={(isLinkLoading && calenderLinkType && calenderLinkType === CALENDER_TYPE.GOOGLE) ? isLinkModalLoading : false}/> :
              null}
          {space?.is_outlook_calendar_integrated ?
              <OutlookCalendar title={'Sync'} onClick={() => handleSpaceSync(CALENDER_TYPE.OUTLOOK)}
                               loading={(isLinkLoading && calenderLinkType && calenderLinkType === CALENDER_TYPE.OUTLOOK) ? isLinkModalLoading : false}/> :
              null}
        </Box> : null}
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
          justifyContent: 'space-between',
          width: "100%",
          marginLeft: '0px !important'
        }}>
          {cowork_id && <ActionItem component={RouterLink} to={linkToVenueCheckIn} text={t('global.viewCheckIn')}/>}
        </Box>
      </CardActions>
    </Card>
  );
}

const ActionItem = ({ text, callback, sx, ...other }) => (
  <Button variant="text" size="small" sx={{ minWidth: 'auto', fontWeight: '500', ...sx }} onClick={callback} {...other}>
    {text}
  </Button>
);

ActionItem.propTypes = {
  text: PropTypes.string,
  callback: PropTypes.func,
  sx: PropTypes.object,
  space: PropTypes.object,
};

const Icons = ({ status, sx, space, ...other }) => {
  const { translate: t } = useLocales();
  return (
    <Stack  direction="row" size="small" className={"not-btn"} sx={{ flex:1 , justifyContent:"end",  minWidth: 'auto', fontWeight: '500',color:"#8C51F5", fontSize: 20, ...sx }} {...other}>
      {space?.is_cowork &&  <Tooltip title={t("venueCreateEdit.form-workspace")} placement="bottom">
        <Box>
          <Iconify icon={'guidance:coworking-space'} size="large" sx={{ mr: 1 }} />
        </Box>
      </Tooltip>
      }
      {space?.is_meeting_room &&
      <Tooltip title={t("venueCreateEdit.form-room")} placement="bottom">
        <Box>
      <Iconify icon={'mdi:virtual-meeting'} size="large" sx={{ mr: 1 }} />
        </Box>
      </Tooltip>
      }

      {space?.is_creative_studio &&
      <Tooltip title={t("venueCreateEdit.form-creative")} placement="bottom">
        <Box>
      <Iconify icon={'fluent-emoji-high-contrast:studio-microphone'} size="large" sx={{ mr: 1 }} />
        </Box>
      </Tooltip>
      }

      {space?.is_office_room &&
      <Tooltip title={t("venueCreateEdit.form-office")} placement="bottom">
        <Box>
      <Iconify icon={'guidance:office'} size="large" sx={{ mr: 1 }} />
        </Box>
      </Tooltip>
      }

    </Stack >
  );
};

Icons.propTypes = {
  status: PropTypes.bool,
  unlink: PropTypes.string,
  link: PropTypes.string,
  sx: PropTypes.object,
};

const GoogleCalendar = ({ status,title, link = '#', sx,loading =false, ...other }) => {
  const { translate: t } = useLocales();

  return (
      <LoadingButton  variant="text" size="small" sx={{ minWidth: 'auto', fontWeight: '500', ...sx }} loading={loading} {...other}>
        {!loading ? <Iconify icon={'logos:google-calendar'} size="small" sx={{mr: 1}}/> : null}{' '}
        {title}
      </LoadingButton >
  );
};

GoogleCalendar.propTypes = {
  status: PropTypes.string,
  link: PropTypes.string,
  sx: PropTypes.object,
};



const OutlookCalendar = ({ status,title, link = '#', sx,loading = false, ...other }) => {
  const { translate: t } = useLocales();

  return (
      <LoadingButton  variant="text" size="small" sx={{ minWidth: 'auto', fontWeight: '500', ...sx }} loading={loading} {...other}>
        {!loading ? <Iconify icon={'vscode-icons:file-type-outlook'} size="small" sx={{mr: 1, height: "18px", width: "18px"}}/>
        :null}
        {' '}
        {title}
      </LoadingButton >
  );
};

OutlookCalendar.propTypes = {
  status: PropTypes.string,
  link: PropTypes.string,
  sx: PropTypes.object,
};
