import PropTypes from 'prop-types';
// import { Container, Alert, AlertTitle } from '@mui/material';
import useAuth from '../hooks/useAuth';
import {ROLE} from "../utils/constants";

RoleBasedDisplay.propTypes = {
  accessibleRoles: PropTypes.array,
  children: PropTypes.node,
};

export default function RoleBasedDisplay({ accessibleRoles, children }) {
  const { user } = useAuth();

  const currentRole = user?.user_type || 0;
  console.log(currentRole,"currentRole",!accessibleRoles.includes(currentRole));
  if (!accessibleRoles.includes(currentRole)) {
    return <></>;
  }

  return <>{children}</>;
}


export function IsRoleBased({ accessibleRoles }) {
  const { user } = useAuth();

  const currentRole = (user?.system_user === ROLE.SUPER_USER || user?.system_user === ROLE.CUSTOMER_SUPPORT)
      ? user?.system_user : (user?.organisation_user_details?.role_type ? user?.organisation_user_details?.role_type : user?.system_user);

  if (!accessibleRoles.includes(currentRole)) {
    return false;
  }

  return true;
}
