import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux'
import { store, persistor } from './redux/store';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';
import './sentry/sentry';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// ----------------------------------------------------------------------

const DEBUG = false; // set to true to disable debugging

if(DEBUG){
    if(!window.console) window.console = {};
    const methods = ["log", "debug", "warn", "info", "error"];
    for(let i=0;i<methods.length;i+=1){
        console[methods[i]] = function silentConsole(){};
    }
}

root.render(
  <ReduxProvider store={store}>
      <Sentry.ErrorBoundary>
    <App />
      </Sentry.ErrorBoundary>
  </ReduxProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
